import React, { useCallback, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Heading, Typography } from '@kintent/glide';

import { Link } from 'react-navi';
import { Grid } from '../../../../design-system';
import { Flex } from '../../../../components/Flex';
import Heartbeat from '../../../../components/Heartbeat';
import PulsatingDot, { PULSATING_DOT_VARIANTS } from '../../../../components/PulsatingDot';
import { useContentVisibility, useControlList } from '../../../../lib/state';
import { hexOpacity } from '../../../../lib/utils';
import { BREAKPOINTS, OTHER_CONTROLS, TRUSTCLOUD_WEBSITE_URL } from '../../../../lib/constants';
import MappingColumnName from '../../../../components/MappingColumnName';
import CardContainer from '../../../../components/CardContainer';
import CountBox from '../../../../components/CountBox';

// Styled components
const ControlMappingSectionDescription = styled(Typography)`
  & > a {
    color: ${({ theme }) => theme.palette.volare};

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
    }

    transition: color 250ms ease-in;
  }
`;

const RelatedControlColumnStack = styled.div`
  & > * + * {
    margin-top: 8px;
  }
`;

const PolicyControlCard = styled(Grid)`
  border-radius: 8px;
  box-shadow: 0 4px 12px ${({ theme }) => hexOpacity(theme.palette.elephant, 0.2)};

  padding: 16px;

  background-color: ${({ theme }) => theme.palette.white};

  grid-template-columns: 1fr 0.1fr;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const AnimationContainer = styled(motion(Flex))`
  cursor: pointer;

  width: 100%;
  height: 22px;
  justify-content: flex-end;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    width: fit-content;
    justify-content: flex-start;
  }
`;

const StyledGrid = styled(Grid)`
  display: none;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    display: block;
  }
`;

const StyledHeading = styled(Heading)`
  &:hover {
    color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  }
`;

function RelatedControlItem({ controlName, controlDetailUrl, controlCategory, shouldHideLinkForControlDetails }) {
  const theme = useTheme();
  const [isHover, setHover] = useState(false);

  const toggleHoverState = useCallback(() => {
    setHover((hover) => !hover);
  }, [setHover]);

  const controlProps = {};
  if (!shouldHideLinkForControlDetails) {
    controlProps.as = Link;
    controlProps.href = controlDetailUrl;
  }

  return (
    <PolicyControlCard
      alignItems="center"
      container
    >
      <Grid
        item
        as={motion.div}
        onHoverStart={toggleHoverState}
        onHoverEnd={toggleHoverState}
      >
        <StyledHeading
          level="9"
          {...controlProps}
        >
          {controlName}
        </StyledHeading>
      </Grid>
      <StyledGrid
        item
        as={Typography}
        level="9"
      >
        {controlCategory}
      </StyledGrid>
      <Grid
        fullWidth
        item
      >
        <AnimationContainer
          alignItems="center"
          justifyContent="center"
          onHoverStart={toggleHoverState}
          onHoverEnd={toggleHoverState}
        >
          <AnimatePresence exitBeforeEnter>
            {!isHover ? (
              <PulsatingDot
                key="pulse-animation"
                exit="exit"
                variants={PULSATING_DOT_VARIANTS}
              />
            ) : (
              <Heartbeat
                key="heartbeat-animation"
                strokeColor={theme.components.header.primaryCTAButton.background}
              />
            )}
          </AnimatePresence>
        </AnimationContainer>
      </Grid>
    </PolicyControlCard>
  );
}

function RelatedControls({ controls }) {
  const { shouldHideControlDetail: shouldHideLinkForControlDetails } = useContentVisibility();

  return controls.map((control) => (
    <RelatedControlItem
      key={control.id}
      controlName={control.categorization.subcategory}
      controlDetailUrl={`/controls/${encodeURIComponent(control.customShortName ?? control.shortName)}`}
      controlCategory={control.categorization?.category ?? OTHER_CONTROLS}
      shouldHideLinkForControlDetails={shouldHideLinkForControlDetails}
    />
  ));
}

function PolicyControlMapping({ policy, controlIds }) {
  const [controls] = useControlList();
  const policyControls = controls.filter((control) => controlIds.includes(control.id));

  return (
    <CardContainer>
      <Flex
        direction="column"
        gap="16px"
      >
        <Flex
          gap="8px"
          alignItems="center"
        >
          <CountBox count={controlIds.length} />
          <Heading level="5">Controls mapped to {policy.title}</Heading>
        </Flex>
        <ControlMappingSectionDescription
          as="p"
          level="8"
        >
          Mapping controls to a policy enabled us to assess our adherence to the practices and procedures in the policy.
          The {policy.title.trim().replace('Policy', 'policy')} is mapped to the following controls in our security,
          privacy, and trust program, which are continuously monitored and verified by{' '}
          <Link
            href={TRUSTCLOUD_WEBSITE_URL}
            target="_blank"
          >
            TrustCloud
          </Link>
          :
        </ControlMappingSectionDescription>
        <RelatedControlColumnStack data-testid="policy-details-controls-mapped">
          <MappingColumnName columnNames={['Control Name', 'Category', 'Monitoring Status']} />
          <RelatedControls controls={policyControls} />
        </RelatedControlColumnStack>
      </Flex>
    </CardContainer>
  );
}

export default PolicyControlMapping;
