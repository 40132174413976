import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@kintent/glide';

import { useAuthService, useTeamSettings } from 'lib/state';
import { Flex } from '../../../../components/Flex';
import DateTime from '../../../../components/DateTime';
import { BREAKPOINTS, EVIDENCE_FREQUENCY_MAP } from '../../../../lib/constants';
import ControlCardHeader from './ControlCardHeader';
import CardContainer from '../../../../components/CardContainer';

const ControlMetadataWrapper = styled(Flex)`
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    flex-wrap: nowrap;
    gap: 32px;
  }
`;

const CustomColorTypography = styled(Typography)`
  color: black;
`;

function ControlMetadata({ control, testCount, specificDescription }) {
  const { currentTeam } = useAuthService();
  const [teamSettings] = useTeamSettings(currentTeam.id);

  const {
    trustShare: { controls },
  } = teamSettings;

  return (
    <CardContainer
      direction="column"
      gap="16px"
    >
      <ControlCardHeader
        control={control}
        specificDescription={specificDescription}
      />
      <ControlMetadataWrapper
        gap="24px"
        wrap
      >
        <Flex
          gap="8px"
          direction="column"
        >
          <Typography
            level="9"
            as="p"
          >
            Control ID
          </Typography>
          <CustomColorTypography
            level="8"
            as="p"
            data-testid="control-id-value"
          >
            {control.customShortName ?? control.shortName}
          </CustomColorTypography>
        </Flex>
        {control.group && (
          <Flex
            gap="8px"
            direction="column"
          >
            <Typography
              level="9"
              as="p"
            >
              Department
            </Typography>
            <CustomColorTypography
              level="8"
              as="p"
            >
              {control.group.name}
            </CustomColorTypography>
          </Flex>
        )}
        {controls.shouldDisplayLastTestedAttribute && control.testingStatus.lastStatusChangeOn && (
          <Flex
            gap="8px"
            direction="column"
          >
            <Typography
              level="9"
              as="p"
            >
              Last Tested
            </Typography>
            <CustomColorTypography
              level="8"
              as="p"
            >
              <DateTime
                date={new Date(control.testingStatus.lastStatusChangeOn)}
                shortMonth
                includeYear
              />
            </CustomColorTypography>
          </Flex>
        )}
        {testCount > 0 && (
          <Flex
            gap="8px"
            direction="column"
          >
            <Typography
              level="9"
              as="p"
            >
              Number of Tests
            </Typography>
            <CustomColorTypography
              level="8"
              as="p"
            >
              {testCount}
            </CustomColorTypography>
          </Flex>
        )}
        <Flex
          gap="8px"
          direction="column"
        >
          <Typography
            level="9"
            as="p"
          >
            Evaluation Frequency
          </Typography>
          <CustomColorTypography
            level="8"
            as="p"
          >
            {EVIDENCE_FREQUENCY_MAP[control.evaluationFrequency.name]}
          </CustomColorTypography>
        </Flex>
      </ControlMetadataWrapper>
    </CardContainer>
  );
}

export default ControlMetadata;
