import React from 'react';

import { useAuthService, useTeamCertificationList } from '../../../../lib/state';
import { DOCUMENT_ACCESS_LEVEL, DOCUMENT_TYPE } from '../../../../lib/constants';
import * as Styles from './DocumentCertificationSection.styles';
import { Flex } from '../../../../components/Flex';
import { getDefaultCertificationDescription, getTeamCertificationsWithChildren } from '../../../../lib/utils';
import DashboardCertificationSectionItemCard from '../../home/components/DashboardCertificationItemCard';
import { ScrollTarget } from './DocumentDetailPanel.styles';

function DocumentCertificationSection() {
  const { currentTeam } = useAuthService();
  const [rawCertifications] = useTeamCertificationList(currentTeam.id);
  const certifications = getTeamCertificationsWithChildren(rawCertifications);
  const visibleCertifications = certifications.filter((item) => {
    return item.children.filter((child) => {
      return [DOCUMENT_ACCESS_LEVEL.PUBLIC, DOCUMENT_ACCESS_LEVEL.PRIVATE].includes(child.trustShareAccessLevel);
    });
  });

  if (!visibleCertifications.length) {
    return null;
  }

  return (
    <Flex
      direction="column"
      gap="16px"
    >
      <ScrollTarget id={DOCUMENT_TYPE.CERTIFICATION} />
      <Styles.DocumentTitle level="5">Certifications</Styles.DocumentTitle>
      <Styles.Grid>
        {visibleCertifications?.map((item) => (
          <DashboardCertificationSectionItemCard
            key={item.id}
            accessLevel={item.trustShareAccessLevel}
            certificationId={item.id}
            certificationUrl={item.certificationUrl}
            documentCount={item.children.length}
            description={
              item.description ??
              getDefaultCertificationDescription(
                currentTeam?.name,
                item.certification.shortName,
                item.status,
                item.subtype
              )
            }
            filename={item.filename}
            shortName={item.certification.shortName}
            status={item.status}
            subtype={item.subtype}
          />
        ))}
      </Styles.Grid>
    </Flex>
  );
}

export default DocumentCertificationSection;
