import { UilPadlock } from '@iconscout/react-unicons';
import React from 'react';
import { Link } from 'react-navi';

import { FEATURE_FLAG } from 'lib/featureFlags';
import { Spinner } from '../../../../components/Spinner';
import { useAuthService, useFeatureFlag, usePolicy, useRequestAccessRedirectionUrl } from '../../../../lib/state';
import { downloadFileFromAPI } from '../../../../lib/utils';
import * as Styles from './DashboardDocuments.styles';
import { GlideButton } from '../../../../components/GlideButton';

const ACTION_ICON_SIZE = 20;

function PolicyActionButton({ canDownload, title, documentId, isDownloading, setDownloading }) {
  const { authenticatedUser } = useAuthService();
  const { requestAccessRedirectionUrl } = useRequestAccessRedirectionUrl();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  const shouldShowRequestAccessButton = !isRequestAccessHidden && !canDownload && !authenticatedUser;

  const { downloadPolicy } = usePolicy(documentId);

  const handlePolicyDownload = async () => {
    setDownloading(true);
    try {
      await downloadFileFromAPI(() => downloadPolicy(), `${title.toLowerCase().replace(' ', '_')}.pdf}`);
    } finally {
      setDownloading(false);
    }
  };

  if (canDownload) {
    return (
      <GlideButton
        variant="secondary"
        icon={isDownloading ? <Spinner /> : <Styles.DownloadIcon size={ACTION_ICON_SIZE} />}
        onClick={() => handlePolicyDownload()}
        disabled={isDownloading}
        className="secondary"
      >
        Download
      </GlideButton>
    );
  }

  if (shouldShowRequestAccessButton) {
    return (
      <Styles.LinkButton
        as={Link}
        variant="secondary"
        icon={<UilPadlock size={ACTION_ICON_SIZE} />}
        href={requestAccessRedirectionUrl}
        className="secondary"
      >
        Request Access
      </Styles.LinkButton>
    );
  }

  return null;
}

export default PolicyActionButton;
