import React from 'react';
import { Heading } from '@kintent/glide';

import { DashboardTrustLeaderItem } from './DashboardTrustLeaderItem';
import * as Styles from './DashboardTrustLeadersSection.styles';
import { SpaceAround } from '../../../../design-system';
import { Flex } from '../../../../components/Flex';
import { useAuthService, useProgramContent, useTeamLeaderList } from '../../../../lib/state';

function DashboardTrustLeadersSection() {
  const { currentTeam } = useAuthService();
  const [trustLeaders] = useTeamLeaderList(currentTeam.id);
  const [{ isLeaderVisible, leaderDescription }] = useProgramContent();

  if (!isLeaderVisible || !trustLeaders.length) {
    return null;
  }

  return (
    <>
      <Styles.TrustLeadersSectionContainer as="section">
        <Flex
          direction="column"
          gap="16px"
        >
          <Flex
            gap="16px"
            alignItems="center"
          >
            <Styles.CountBadge variant="primary">{trustLeaders.length}</Styles.CountBadge>
            <Heading level="3">Trust Leadership</Heading>
          </Flex>
          <SpaceAround bottom="8px">
            <Styles.DescriptionLabel level="8">{leaderDescription}</Styles.DescriptionLabel>
          </SpaceAround>
          <Styles.FlexContainer
            gap="24px"
            wrap
          >
            {trustLeaders.map((trustLeader) => (
              <DashboardTrustLeaderItem
                key={trustLeader.id}
                name={`${trustLeader.firstName} ${trustLeader.lastName}`}
                trustLeader={trustLeader}
              />
            ))}
          </Styles.FlexContainer>
        </Flex>
      </Styles.TrustLeadersSectionContainer>
    </>
  );
}

export default DashboardTrustLeadersSection;
