// Package modules
import React from 'react';
import { Link } from 'react-navi';
import styled from '@emotion/styled';
import { UilFacebookF as FacebookIcon, UilTwitter as TwitterIcon, UilLinkedin } from '@iconscout/react-unicons';
import { Heading } from '@kintent/glide';
import { useTheme } from '@emotion/react';

// Locale modules
import { Flex } from './Flex';
import { BREAKPOINTS, FACEBOOK_APP_ID, TRUSTCLOUD_WEBSITE_URL } from '../lib/constants';
import { useAuthService } from '../lib/state';

// Assets
import trustCloudLogo from '../assets/home/trustcloud-new-logo.svg';

// Styled components
const Footer = styled(Flex)`
  flex-grow: 0; // Restrict the footer from growing
  margin-top: auto;
  flex-direction: column-reverse;
  gap: 20px;

  padding: 1rem;

  background-color: white;
  background-size: cover;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      flex-direction: row;
      justify-content: space-between;
      padding: 1.5rem 3rem;
    }
  }
`;

const TrustCloudBlurb = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  & > h6 {
    width: 260px;
    text-align: center;
  }

  & > div {
    cursor: pointer;
    transition: transform ease-in 150ms;

    :hover {
      transform: translateY(-2px);
    }
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      flex-direction: row;
      gap: 48px;

      & > h6 {
        text-align: initial;
      }
    }
  }
`;

const SocialMediaBlurb = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  & > h6 {
    width: 260px;
    text-align: center;
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      align-items: flex-end;

      & > h6 {
        width: fit-content;
        text-align: initial;
      }
    }
  }
`;

const SocialMediaIconLink = styled(Link)`
  &:hover {
    transform: translateY(-2px);
  }

  transition: transform 0.15s ease-in;
`;

const LearnMoreLink = styled.a`
  color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export function AppFooter() {
  const theme = useTheme();
  const { currentTeam } = useAuthService();
  let url;
  let uriEncodedUrl;
  let uriEncodedShareText;

  if (currentTeam) {
    url = currentTeam?.trustShareUrl;
    uriEncodedUrl = encodeURIComponent(url);
    uriEncodedShareText = encodeURIComponent(
      `Kudos to ${currentTeam?.name} for being transparent about their security and privacy program. Goes a long way in earning customer #Trust. Check out their #TrustShare here -`
    );
  }

  return (
    <Footer
      as="footer"
      justifyContent="space-between"
    >
      <TrustCloudBlurb>
        <Flex
          direction="column"
          gap="8px"
          onClick={() => window.open(TRUSTCLOUD_WEBSITE_URL, '_blank')}
        >
          <Heading level="8">Truthfully crafted by</Heading>
          <img
            src={trustCloudLogo}
            alt="TrustCloud Logo"
            height={38}
          />
        </Flex>
        <Heading level="8">
          Accelerate revenue and earn trust with your own unified, joyfully-crafted trust assurance platform.{' '}
          <LearnMoreLink
            href={TRUSTCLOUD_WEBSITE_URL}
            target="_blank"
          >
            Learn More
          </LearnMoreLink>
        </Heading>
      </TrustCloudBlurb>
      {currentTeam && (
        <SocialMediaBlurb>
          <Flex
            gap="20px"
            justifyContent="flex-end"
          >
            <Flex
              href={`https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID}&title=${
                currentTeam?.name
              }&display=page&hashtag=${encodeURIComponent(`#${currentTeam?.name}`)}&href=${uriEncodedUrl}`}
              as={SocialMediaIconLink}
              target="_blank"
            >
              <FacebookIcon
                size={24}
                fill={theme.components.header.primaryCTAButton.background}
              />
            </Flex>
            <Flex
              href={`https://twitter.com/intent/tweet?text=${uriEncodedShareText}&url=${uriEncodedUrl}`}
              as={SocialMediaIconLink}
              target="_blank"
            >
              <TwitterIcon
                size={24}
                fill={theme.components.header.primaryCTAButton.background}
              />
            </Flex>
            <Flex
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${uriEncodedUrl}`}
              as={SocialMediaIconLink}
              target="_blank"
            >
              <UilLinkedin
                size={24}
                fill={theme.components.header.primaryCTAButton.background}
              />
            </Flex>
          </Flex>
          {currentTeam && <Heading level="8">Give {currentTeam?.name} a shout-out about their transparency</Heading>}
        </SocialMediaBlurb>
      )}
    </Footer>
  );
}
