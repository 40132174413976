import styled from '@emotion/styled';
import { base, flat, Table, translucent } from '@kintent/glide';
import PageContainer from '../../../components/PageContainer';

export const NotificationsPageContainer = styled(PageContainer)`
  padding-bottom: 60px;
`;

export const NotificationCenterContainer = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  border-radius: 8px;

  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '10%')};

  overflow: hidden;

  div[role='toolbar'] {
    &:first-of-type {
      display: none;
    }

    & > div {
      & > button {
        color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
        &:disabled {
          color: ${({ theme }) => flat(theme.color.system.gray, '20%')};
        }
      }
    }
  }
`;

export const NotificationTable = styled(Table)`
  .TableHeader {
    display: none;
  }
`;
