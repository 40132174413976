// Package modules
import React from 'react';
import { useCurrentRoute } from 'react-navi';
import styled from '@emotion/styled';
import { Heading, Typography } from '@kintent/glide';

// Local modules
import { Grid } from '../../../design-system';
import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import { Flex } from '../../../components/Flex';
import { Helmet } from '../../../components/Helmet';
import { CertificationListCard } from '../../../components/CertificationListCard';
import { ResponsiveContainer } from '../../../components/ResponsiveContainer';
import { FloatingRequestAccessButton } from '../../../components/FloatingRequestAccessButton';
import { useAuthService, useProgramContent, useTeamCertificationList } from '../../../lib/state';
import { BREAKPOINTS } from '../../../lib/constants';
import { useMeasurePaint, useMeasureRender, useMeasureRoute } from '../../../lib/measurement';

// Styled components
const StyledGrid = styled(Grid)`
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: min-content;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      column-gap: 60px;
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      grid-template-columns: repeat(2, 400px);
    }
  }
`;

export const CertificationList = withTrustShareNavigationBar(() => {
  const {
    data: { span, title },
  } = useCurrentRoute();
  span.setAttribute('page-name', 'certifications');
  useMeasureRoute(span);
  const { currentTeam, authenticatedUser } = useAuthService();
  const [{ certificationTitle, certificationDescription }] = useProgramContent();

  // Retrieve team info. to be used to fetch team based certifications
  const [teamCertifications] = useTeamCertificationList(currentTeam.id);

  const tree = (
    <ResponsiveContainer
      as={Flex}
      direction="column"
      gap="3rem"
    >
      <Helmet pageTitle={title} />
      <Flex
        direction="column"
        gap="0.5rem"
      >
        <Heading level="1">{certificationTitle}</Heading>
        <Typography level="body">{certificationDescription}</Typography>
      </Flex>
      <StyledGrid
        segments={2}
        direction="horizontal"
        alignItems="stretch"
        columnSpacing={1}
        rowSpacing={1.5}
        container
      >
        {teamCertifications.map(
          ({
            id,
            filename = null,
            subtype = null,
            status,
            certificationUrl,
            description,
            trustShareAccessLevel,
            certification: { name, shortName, logoUrl },
          }) => (
            <Grid
              item
              key={id}
              as={CertificationListCard}
              accessLevel={trustShareAccessLevel}
              isLoggedIn={authenticatedUser !== null}
              teamCertificationId={id}
              name={name}
              shortName={shortName}
              subtype={subtype}
              status={status}
              description={description}
              certificationUrl={certificationUrl}
              logoUrl={logoUrl}
              filename={filename}
            />
          )
        )}
      </StyledGrid>
      <FloatingRequestAccessButton />
    </ResponsiveContainer>
  );

  useMeasureRender(span);
  useMeasurePaint(span);

  return tree;
});
