import React from 'react';
import { flat, Heading } from '@kintent/glide';
import { useTheme } from '@emotion/react';
import { Link } from 'react-navi';

import * as Styles from './DashboardPolicySection.styles';
import DashboardPolicySectionItem from './DashboardPolicySectionItem';
import Heartbeat from '../../../../components/Heartbeat';
import { Flex } from '../../../../components/Flex';
import { useContentVisibility, usePolicyList } from '../../../../lib/state';
import { getPoliciesForGroup, sortByProp } from '../../../../lib/utils';
import { DOCUMENT_ACCESS_LEVEL, POLICY_SECURITY_GROUPS } from '../../../../lib/constants';

const sortByTitle = sortByProp('title');
const SECURITY_GROUPS = Object.values(POLICY_SECURITY_GROUPS);
const MAX_LIMIT = 4;

function DashboardPolicySection() {
  const theme = useTheme();

  const { shouldHidePolicyDetail: shouldHideLinkForPolicyDetails } = useContentVisibility();
  const [policies] = usePolicyList();
  const visiblePolicies = policies
    .filter((policy) =>
      [DOCUMENT_ACCESS_LEVEL.PUBLIC, DOCUMENT_ACCESS_LEVEL.PRIVATE].includes(policy.trustShareAccessLevel)
    )
    .sort(sortByTitle);

  if (policies.length === 0) return null;

  return (
    <Styles.PolicySectionContainer as="section">
      <Flex
        gap="16px"
        alignItems="center"
      >
        <Styles.CountBadge variant="primary">{visiblePolicies.length}</Styles.CountBadge>
        <Heading level="3">Policies</Heading>
        <Heartbeat strokeColor={flat(theme.color.system.positive)} />
      </Flex>
      <Styles.Grid>
        {SECURITY_GROUPS.map((group) => {
          const policiesPerGroup = getPoliciesForGroup(visiblePolicies, group);

          if (policiesPerGroup.length === 0) return null;
          return (
            <Styles.Card
              key={group}
              gap="16px"
              direction="column"
            >
              <Flex
                alignItems="center"
                gap="4px"
              >
                <Heading level="5">{group}</Heading>
                <Styles.PolicyLengthLabel level="6">·</Styles.PolicyLengthLabel>
                <Styles.PolicyLengthLabel level="6">{policiesPerGroup.length}</Styles.PolicyLengthLabel>
              </Flex>
              <div>
                {policiesPerGroup.map(
                  (policy, idx) =>
                    idx < MAX_LIMIT && (
                      <DashboardPolicySectionItem
                        accessLevel={policy.trustShareAccessLevel}
                        key={policy.id}
                        policyId={policy.id}
                        shortName={policy.shortName}
                        shouldHideLinkForPolicyDetails={shouldHideLinkForPolicyDetails}
                        title={policy.title}
                        dataRoomIds={policy.dataRoomIds}
                      />
                    )
                )}
              </div>
              {!shouldHideLinkForPolicyDetails && (
                <Styles.ViewAllLink
                  as={Link}
                  href="/policies"
                  level="7"
                >
                  View All
                </Styles.ViewAllLink>
              )}
            </Styles.Card>
          );
        })}
      </Styles.Grid>
    </Styles.PolicySectionContainer>
  );
}

export default DashboardPolicySection;
