import styled from '@emotion/styled';
import { Flex } from '../../../../components/Flex';

export const DocumentDetailContainer = styled(Flex)`
  position: relative;

  overflow: visible;

  flex: 1;

  padding-bottom: 20px;
`;

export const ScrollTarget = styled.div`
  position: relative;
  top: -150px;
`;
