import React from 'react';

import DocumentHeader from './DocumentHeader';
import * as Styles from './DocumentDetailPanel.styles';
import DocumentSharedWithSection from './DocumentSharedWithSection';
import DocumentCertificationSection from './DocumentCertificationSection';
import DocumentPolicySection from './DocumentPolicySection';
import DocumentResourceSection from './DocumentResourceSection';

function DocumentDetailPanel({ documentsSharedWithUser }) {
  return (
    <Styles.DocumentDetailContainer
      direction="column"
      gap="72px"
    >
      <DocumentHeader />
      <DocumentSharedWithSection documentsSharedWithUser={documentsSharedWithUser} />
      <DocumentCertificationSection />
      <DocumentPolicySection />
      <DocumentResourceSection />
    </Styles.DocumentDetailContainer>
  );
}

export default DocumentDetailPanel;
