import styled from '@emotion/styled';

import { flat, Heading, rgb, Typography } from '@kintent/glide';

// Local modules
import { Flex } from 'components/Flex';
import { BREAKPOINTS } from 'lib/constants';
import contactUsSvg from '../../../../assets/contact-us.svg';
import HomePageContainer from './HomePageContainer';
import { Modal } from '../../../../design-system';
import { GlideButton } from '../../../../components/GlideButton';

// Styled components
export const Container = styled('div', {
  shouldForwardProp(props) {
    return !['backgroundGradientColor'].includes(props);
  },
})`
  background-image: linear-gradient(
    to top,
    ${({ theme, backgroundGradientColor }) => backgroundGradientColor || flat(theme.color.brand.lighter)},
    ${({ theme }) => rgb(theme.color.system.white, 0)}
  );
  margin-top: 64px;
`;

export const StyledFlex = styled(Flex)`
  padding-top: 240px;
  min-height: 640px;
  height: 100%;
`;

export const StyledSection = styled(HomePageContainer)`
  height: 100%;
  background: url(${contactUsSvg}) no-repeat;
  background-position: right;
`;

export const StyledActionButton = styled(GlideButton)`
  margin-top: 32px;
`;

export const StyledModal = styled(Modal)`
  width: 350px;
  max-height: 100%;
  overflow: visible; /* Enable scroll if modal content exceeds viewport height */

  /* Patch Firefox ignoring padding-bottom with overflow: auto.*/
  /* https://bugzilla.mozilla.org/show_bug.cgi?id=748518 */
  padding: 20px 20px 0 20px;

  & > :last-child {
    padding-bottom: 32px;

    & > button {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      padding: 32px 32px 0 32px;
      width: 635px;
    }

    & > :last-child {
      padding-bottom: 32px;
    }
  }
`;

export const StyledHeader = styled(Heading)`
  margin-bottom: 8px;
  color: ${({ theme }) => flat(theme.color.system.gray, '100%')};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledTypography = styled(Typography)`
  margin-bottom: 4px;
  color: ${({ theme }) => flat(theme.color.system.gray, '80%')};
`;

export const StyledFlexForm = styled(Flex)`
  width: 100%;
  margin-bottom: 0;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-gap: 2px;
  width: 100%;
  margin-top: 8px;
`;

export const FormContainerGrid = styled.div`
  display: grid;
  grid-gap: 2px;
  &:last-child {
    margin-bottom: 48px;
  }
`;

export const ProspectEmailLabel = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray, '100%')};
`;

export const DescriptionLabel = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray, '80%')};
`;
