import styled from '@emotion/styled';
import { Button } from '@kintent/glide';

import { darkenHexColor, hexOpacity, lightenHexColor } from '../lib/utils';

export const GlideButton = styled(Button)`
  width: fit-content;
  color: ${({ theme }) => theme.components.header.primaryCTAButton.textColor};
  background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};

  &:hover {
    background-color: ${({ theme }) => darkenHexColor(theme.components.header.primaryCTAButton.background, 50)};
  }

  &:active,
  &:focus {
    box-shadow: 0 0 8px ${({ theme }) => hexOpacity(theme.components.header.primaryCTAButton.background, 0.2)};
  }

  &.secondary {
    color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
    background-color: white;
    border-color: ${({ theme }) => hexOpacity(theme.components.header.primaryCTAButton.background, 0.1)};

    &:hover {
      background-color: ${({ theme }) => lightenHexColor(theme.components.header.primaryCTAButton.background, 90)};
    }
  }

  &.ghost {
    color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
    background-color: transparent;
  }
`;
