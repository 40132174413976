import { useTheme } from '@emotion/react';
import { UilEllipsisH } from '@iconscout/react-unicons';
import { Typography } from '@kintent/glide';
import React from 'react';
import { Link } from '../../../../components/Link';

import * as Styles from './DashboardDocuments.styles';

function DashboardDocumentCardViewMore({ count }) {
  const theme = useTheme();

  return (
    <Styles.GridItem>
      <Typography level="8">+ {count} more</Typography>
      <UilEllipsisH
        size={20}
        fill={theme.components.header.primaryCTAButton.background}
      />
      <Styles.Overlay className="overlay">
        <Styles.LinkButton
          as={Link}
          href="/documents"
          target="_blank"
          variant="secondary"
          className="secondary"
        >
          View All
        </Styles.LinkButton>
      </Styles.Overlay>
    </Styles.GridItem>
  );
}

export default DashboardDocumentCardViewMore;
