// Package modules
import React, { Suspense, useState } from 'react';
import styled from '@emotion/styled';
import ReactDOM from 'react-dom';
import { NotFoundBoundary, Router, View } from 'react-navi';
import { HelmetProvider } from 'react-navi-helmet';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import opentelemetry from '@opentelemetry/api';
import { InternalTheme } from '@kintent/glide';

// Local modules
import { api } from 'lib/api';
import './tracing';
import { TrustCloudLogo } from './components/TrustCloudLogo';
import documentLoad from './documentLoad';
import { ErrorProvider } from './lib/ErrorProvider';
import { Flex } from './components/Flex';
import { theme, Provider as ThemeProvider } from './components/theme';
import { ErrorBoundary } from './components/ErrorBoundary';
import { NotFoundPage } from './components/NotFoundPage';
import { AuthService } from './lib/authService';
import { routes } from './routes';
import { DEFAULT_STALE_TIME } from './lib/constants';
import initializeSentry from './lib/errors/sentry';

const tracer = opentelemetry.trace.getTracer('page-tracer');
let START_TIME = new Date().getTime();

// Protect data passed to the DOM against XSS.
window.trustedTypes?.createPolicy('default', {
  // Note: temp passthrough to fix browser warnings - we should put actual filtering in place.
  createHTML: (value) => value,
  createScript: (value) => value,
  createScriptURL: (url) => url,
});

// Styled Components
const LogoWrapperFlex = styled(Flex)`
  height: 100vh;

  background-color: ${theme.palette.bone};
`;

const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      staleTime: DEFAULT_STALE_TIME,
    },
  },
});

initializeSentry();

const handleVisibilityChange = () => {
  if (document.visibilityState === 'hidden') {
    const endTime = new Date().getTime();
    const sessionLength = endTime - START_TIME;
    api.track.insertActivity({ sessionLength });

    // Resets the start time for the next session
    START_TIME = new Date().getTime();
  }
};

const handleBeforeUnload = () => {
  const endTime = new Date().getTime();
  const sessionLength = endTime - START_TIME;
  api.track.insertActivity({ sessionLength });

  // Resets the start time for the next session
  START_TIME = new Date().getTime();
};

document.addEventListener('visibilitychange', handleVisibilityChange);
window.addEventListener('beforeunload', handleBeforeUnload);

function MainWrapper() {
  const [publicTeamId, setPublicTeamId] = useState(null);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  AuthService.getAuthServiceInstance().setQueryClient(reactQueryClient);
  AuthService.getAuthServiceInstance().subscribeToPublicAuthentication(setPublicTeamId);
  AuthService.getAuthServiceInstance().subscribeToAuthenticatedUser(setAuthenticatedUser);

  return (
    <HelmetProvider>
      <Helmet defaultTitle={theme.metaTitle} />
      <Router
        routes={routes}
        context={{ publicTeamId, authenticatedUser }}
      >
        <ThemeProvider theme={theme}>
          <ThemeProvider theme={InternalTheme}>
            <QueryClientProvider client={reactQueryClient}>
              <Suspense
                fallback={
                  <LogoWrapperFlex
                    alignItems="center"
                    justifyContent="center"
                  >
                    <TrustCloudLogo />
                  </LogoWrapperFlex>
                }
              >
                <ErrorProvider.Provider>
                  <ErrorBoundary>
                    <NotFoundBoundary render={NotFoundPage}>
                      <View />
                    </NotFoundBoundary>
                  </ErrorBoundary>
                </ErrorProvider.Provider>
              </Suspense>
            </QueryClientProvider>
          </ThemeProvider>
        </ThemeProvider>
      </Router>
    </HelmetProvider>
  );
}

tracer.startActiveSpan('main', (rootSpan) => {
  documentLoad();
  ReactDOM.render(<MainWrapper />, document.getElementById('root'));
  rootSpan.addEvent('page-rendered');
  rootSpan.end();
});
