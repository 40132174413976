import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { UilImport as DownloadIcon, UilKeySkeleton as KeyIcon } from '@iconscout/react-unicons';
import { Heading, Typography } from '@kintent/glide';

import { FEATURE_FLAG } from 'lib/featureFlags';
import { useNavigation } from 'react-navi';
import { Spinner } from '../../../../components/Spinner';
import DateTime from '../../../../components/DateTime';
import { Flex } from '../../../../components/Flex';
import Heartbeat from '../../../../components/Heartbeat';
import { useAuthService, useFeatureFlag, usePolicy, useRequestAccessRedirectionUrl } from '../../../../lib/state';
import { canDownloadPolicy, downloadFileFromAPI, hasDataRoomAccessToDownloadPolicy } from '../../../../lib/utils';
import { BREAKPOINTS } from '../../../../lib/constants';
import CardContainer from '../../../../components/CardContainer';
import { GlideButton } from '../../../../components/GlideButton';

// Styled components
const MetadataContainer = styled.div`
  & > h5 {
    padding-top: 10px;
  }

  & > p {
    padding-top: 4px;
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & > div {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 0;
    }
  }
`;

const PolicyFieldWrapper = styled(Flex)`
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    flex-wrap: nowrap;
    gap: 32px;
  }
`;

const DownloadButton = styled(GlideButton)`
  & svg {
    margin-left: 8px;
  }
`;

const PolicyField = ({ fieldName, fieldValue }) => (
  <Flex
    direction="column"
    gap="8px"
  >
    <Typography
      as="p"
      level="9"
    >
      {fieldName}
    </Typography>
    <Heading
      as="p"
      level="8"
    >
      {fieldValue}
    </Heading>
  </Flex>
);

function PolicyMetadata({
  id,
  title,
  description,
  shortName,
  department = null,
  approvalDate = null,
  accessLevel,
  dataRoomIds,
}) {
  const theme = useTheme();
  const { navigate } = useNavigation();
  const { authenticatedUser } = useAuthService();
  const [isPolicyDownloading, setDownloadingPolicy] = useState(false);
  const { downloadPolicy } = usePolicy(id);
  const needsPolicyDataRoomAccess = useFeatureFlag(FEATURE_FLAG.POLICY_DATA_ROOM_ACCESS);
  const canDownload =
    hasDataRoomAccessToDownloadPolicy(authenticatedUser, dataRoomIds, needsPolicyDataRoomAccess) &&
    canDownloadPolicy(accessLevel, authenticatedUser);
  const { requestAccessRedirectionUrl } = useRequestAccessRedirectionUrl();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  // TODO - This should be handled based on the access level of the policy
  const handlePolicyDownload = async () => {
    setDownloadingPolicy(true);
    try {
      await downloadFileFromAPI(() => downloadPolicy(), `${title.toLowerCase().replace(' ', '_')}.pdf}`);
    } finally {
      setDownloadingPolicy(false);
    }
  };

  const handleRequestAccess = useCallback(() => {
    navigate(requestAccessRedirectionUrl);
  }, [navigate, requestAccessRedirectionUrl]);

  return (
    <CardContainer
      direction="column"
      gap="16px"
    >
      <MetadataContainer>
        <Flex
          gap="12px"
          direction="column-reverse"
          alignItems="flex-start"
        >
          <Heading level="5">{title}</Heading>
          <Flex
            alignItems="center"
            gap="8px"
          >
            <Heartbeat strokeColor={theme.palette.jade} />
            <Typography
              level="9"
              as="p"
            >
              Continuously assessed for adherence and risk
            </Typography>
          </Flex>
        </Flex>
        <Typography
          level="8"
          as="p"
        >
          {description}
        </Typography>
      </MetadataContainer>
      <PolicyFieldWrapper gap="24px">
        <PolicyField
          fieldName="Policy ID"
          fieldValue={shortName}
        />
        {department && (
          <PolicyField
            fieldName="Department"
            fieldValue={department}
          />
        )}
        {approvalDate && (
          <PolicyField
            fieldName="Last Approved"
            fieldValue={
              <DateTime
                date={new Date(approvalDate)}
                shortMonth
                includeYear
              />
            }
          />
        )}
      </PolicyFieldWrapper>
      {canDownload && (
        <DownloadButton
          onClick={handlePolicyDownload}
          disabled={isPolicyDownloading}
        >
          Download
          {isPolicyDownloading ? <Spinner size={16} /> : <DownloadIcon size={16} />}
        </DownloadButton>
      )}
      {!canDownload && !authenticatedUser && !isRequestAccessHidden && (
        <DownloadButton onClick={handleRequestAccess}>
          Request access to download
          <KeyIcon size={16} />
        </DownloadButton>
      )}
    </CardContainer>
  );
}

export default PolicyMetadata;
