import React from 'react';
import { UilSignout, UilUserSquare } from '@iconscout/react-unicons';
import styled from '@emotion/styled';
import { Menu, MenuItem, MenuSeparator } from 'reakit';
import { useNavigation } from 'react-navi';
import { useTheme } from '@emotion/react';
import { Typography } from '@kintent/glide';

import { Flex } from '../../../components/Flex';
import { hexOpacity } from '../../../lib/utils';
import { useAuthService } from '../../../lib/state';

const AccountSummaryMenu = styled(Menu)`
  box-shadow: 0 0 10px 1px ${({ theme }) => hexOpacity(theme.palette.granite, 0.3)};
  border-radius: 8px;

  min-width: 200px;

  padding-top: 8px;
  padding-bottom: 8px;

  background-color: ${({ theme }) => theme.palette.white};

  outline: none;
  overflow: hidden;

  transition: opacity 100ms;
  opacity: 0;
  &[data-enter] {
    opacity: 1;
  }
`;

const StyledMenuItems = styled.div`
  opacity: 0;
  transform: translateY(-2px);

  transition: opacity 200ms ease-in, transform 100ms ease-in;

  [data-enter] & {
    opacity: 1;
    transform: translateY(0);
  }
`;

const UserMenuItem = styled(Flex)`
  padding: 0.5em 1.25em;

  background-color: ${({ theme }) => theme.palette.white};
  border: none;
  text-align: left;

  line-height: 1.7;

  color: ${({ theme }) => theme.palette.elephant};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.palette.shadow};

    background-color: ${({ theme }) => hexOpacity(theme.components.header.primaryCTAButton.background, 0.1)};
  }
`;

const StyledMenuSeparator = styled(MenuSeparator)`
  border-color: ${({ theme }) => hexOpacity(theme.palette.silver, 0.5)};
  border-style: solid;
  border-top: 0;
`;

export const AccountMenu = ({ menu }) => {
  const theme = useTheme();
  const { navigate } = useNavigation();
  const { logout } = useAuthService();

  const handleLogout = () => {
    logout();
    navigate('/home');
  };

  const handleNavigationToAccountsPage = () => {
    navigate('/account');
    menu.hide();
  };

  return (
    <>
      <AccountSummaryMenu
        aria-label="Preferences"
        {...menu}
      >
        <StyledMenuItems>
          <UserMenuItem
            alignItems="center"
            gap="12px"
            onClick={handleNavigationToAccountsPage}
          >
            <UilUserSquare
              color={theme.components.header.primaryCTAButton.background}
              size={20}
            />
            <MenuItem
              as={Typography}
              level="8"
            >
              Account Summary
            </MenuItem>
          </UserMenuItem>
          <StyledMenuSeparator {...menu} />
          <UserMenuItem
            alignItems="center"
            gap="12px"
            onClick={handleLogout}
          >
            <UilSignout
              color={theme.components.header.primaryCTAButton.background}
              size={20}
            />
            <MenuItem
              as={Typography}
              level="8"
            >
              Sign Out
            </MenuItem>
          </UserMenuItem>
        </StyledMenuItems>
      </AccountSummaryMenu>
    </>
  );
};
