import React, { useState } from 'react';
import { Link, useNavigation } from 'react-navi';
import { useTheme } from '@emotion/react';
import { flat } from '@kintent/glide';
import { UilFileAlt, UilImport, UilPadlock } from '@iconscout/react-unicons';
import * as Sentry from '@sentry/react';

import { FEATURE_FLAG } from 'lib/featureFlags';
import * as Styles from './DashboardPolicySectionItem.styles';
import { Flex } from '../../../../components/Flex';
import { Spinner } from '../../../../components/Spinner';
import { useAuthService, useFeatureFlag, usePolicy, useRequestAccessRedirectionUrl } from '../../../../lib/state';
import {
  canDownloadPolicy,
  downloadFileFromAPI,
  hasDataRoomAccessToDownloadPolicy,
  shouldHideItem,
} from '../../../../lib/utils';

const ICON_SIZE = 20;

function ActionIcon({ canDownload = false, policyId, title }) {
  const theme = useTheme();
  const { navigate } = useNavigation();
  const { downloadPolicy } = usePolicy(policyId);
  const { requestAccessRedirectionUrl } = useRequestAccessRedirectionUrl();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  const [isDownloadingPolicy, setDownloadingPolicy] = useState(false);
  const shouldHideRequestAccess = shouldHideItem(isRequestAccessHidden, []);

  const handlePolicyDownload = async () => {
    setDownloadingPolicy(true);

    try {
      await downloadFileFromAPI(() => downloadPolicy(), `${title.toLowerCase().replace(' ', '_')}.pdf`);
    } catch (e) {
      Sentry.captureException(e);
    }
    setDownloadingPolicy(false);
  };

  if (canDownload) {
    return isDownloadingPolicy ? (
      <Spinner
        size={ICON_SIZE}
        fill={theme.components.header.primaryCTAButton.background}
      />
    ) : (
      <Styles.IconAction onClick={handlePolicyDownload}>
        <UilImport
          size={ICON_SIZE}
          fill={theme.components.header.primaryCTAButton.background}
        />
      </Styles.IconAction>
    );
  }

  return (
    !shouldHideRequestAccess && (
      <Styles.IconAction onClick={() => navigate(requestAccessRedirectionUrl)}>
        <UilPadlock
          size={ICON_SIZE}
          fill={theme.components.header.primaryCTAButton.background}
        />
      </Styles.IconAction>
    )
  );
}

function DashboardPolicySectionItem({
  accessLevel,
  policyId,
  shortName,
  shouldHideLinkForPolicyDetails,
  title,
  dataRoomIds,
}) {
  const theme = useTheme();
  const { authenticatedUser } = useAuthService();
  const needsPolicyDataRoomAccess = useFeatureFlag(FEATURE_FLAG.POLICY_DATA_ROOM_ACCESS);

  const canDownload =
    hasDataRoomAccessToDownloadPolicy(authenticatedUser, dataRoomIds, needsPolicyDataRoomAccess) &&
    canDownloadPolicy(accessLevel, authenticatedUser);

  const policyProps = {
    href: shouldHideLinkForPolicyDetails ? null : `/policies/${encodeURIComponent(shortName)}`,
    as: shouldHideLinkForPolicyDetails ? 'p' : Link,
  };

  return (
    <Styles.Item
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex
        alignItems="center"
        gap="12px"
      >
        <div>
          <UilFileAlt
            size={ICON_SIZE}
            fill={flat(theme.color.system.gray, '40%')}
          />
        </div>
        <Styles.Title
          level="8"
          {...policyProps}
        >
          {title}
        </Styles.Title>
      </Flex>
      <ActionIcon
        canDownload={canDownload}
        policyId={policyId}
        title={title}
      />
    </Styles.Item>
  );
}

export default DashboardPolicySectionItem;
