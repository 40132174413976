import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useNavigation } from 'react-navi';
import { useCheckboxState, useRoverState } from 'reakit';
import { useContainer } from 'unstated-next';

import { BREAKPOINTS, NDA_ACKNOWLEDGEMENT_STEPS, RESOURCE_TYPE } from '../../lib/constants';
import {
  useAuthService,
  useCompany,
  useDownloadNDADocument,
  useNDA,
  useTeamSettings,
  useVerifyProspectAttestation,
} from '../../lib/state';
import { hexOpacity } from '../../lib/utils';
import { LoadingSpinner } from '../LoadingSpinner';
import NdaAgreementStep from './components/NdaAgreementStep';
import NdaRejectionStep from './components/NdaRejectionStep';
import WelcomeStep from './components/WelcomeStep';
import { Modal, Step, useModal } from '../../design-system';
import { ErrorProvider } from '../../lib/ErrorProvider';

// Styled components
const StyledModal = styled(Modal)`
  width: 350px;
  max-height: 100%;
  overflow: visible; /* Enable scroll if modal content exceeds viewport height */

  /* Patch Firefox ignoring padding-bottom with overflow: auto.*/
  /* https://bugzilla.mozilla.org/show_bug.cgi?id=748518 */
  padding: 20px 20px 0 20px;

  & > :last-child {
    padding-bottom: 32px;
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      padding: 32px 32px 0 32px;
      width: 635px;
    }

    & > :last-child {
      padding-bottom: 32px;
    }
  }
`;

const StyledSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensure spinner is on top of modal content.*/

  border-radius: 8px; /*This is the same as the modal border radius*/
  background-color: ${({ theme }) => hexOpacity(theme.palette.shadow, 0.2)};
`;

function NdaAcknowledgementFlow() {
  const { currentTeam, authenticatedUser, logout, updateAuth, authService } = useAuthService();
  const [teamSettings] = useTeamSettings(currentTeam.id);
  const { navigate } = useNavigation();
  const { createNda } = useNDA();
  const { patchCompany } = useCompany(authenticatedUser?.programCompany?.id);
  const { setError } = useContainer(ErrorProvider);
  const { data: url } = useDownloadNDADocument();
  const { verifyProspectAttestation } = useVerifyProspectAttestation();

  const [stepModalProps, setModalProps] = useState({});
  const [isNdaSubmitting, setIsNdaSubmitting] = useState(false);

  const ndaAcknowledgementModal = useModal();
  const ndaAcknowledgementSteps = useRoverState({ currentId: NDA_ACKNOWLEDGEMENT_STEPS.WELCOME });
  const checkboxState = useCheckboxState({ state: false });

  const isNdaFlowEnabled = teamSettings.trustShare?.nda?.isAttestationEnabled;
  const isNdaSigned = Boolean(authenticatedUser?.programCompany?.trustShareNdaId);
  const salutationMessage = teamSettings.trustShare?.nda?.salutationMessage;

  useEffect(() => {
    if (isNdaFlowEnabled && !isNdaSigned && authenticatedUser !== null) {
      ndaAcknowledgementModal.show();
    }
  }, [isNdaFlowEnabled]);

  useEffect(() => {
    if (ndaAcknowledgementSteps.currentId === NDA_ACKNOWLEDGEMENT_STEPS.NDA_AGREEMENT) {
      setModalProps({
        ...stepModalProps,
        enablePrimary: checkboxState.state,
      });
    }
  }, [checkboxState.state]);
  // This piece ensures we exit the NDA Attestation workflow either when the setting is disabled i.e. set to false or when the prospect's company has already attested to an NDA
  if (!ndaAcknowledgementModal.isOpen) {
    return null;
  }
  const handleNdaAgreementAcceptance = async () => {
    setIsNdaSubmitting(true);
    try {
      const ndaCreationPayload = {
        companyDomain: authenticatedUser.email.split('@')[1],
        company: authenticatedUser.programCompany?.name ?? authenticatedUser.company,
        ndaType: RESOURCE_TYPE.LINK,
        dateSigned: new Date(),
        attestedForUser: authenticatedUser.id,
      };

      const createNdaResponse = await createNda(ndaCreationPayload);

      await Promise.all([
        patchCompany({
          trustShareNdaId: createNdaResponse.id,
        }),
        verifyProspectAttestation({
          name: authenticatedUser.name,
          company: authenticatedUser.company,
          email: authenticatedUser.email,
        }),
      ]);
      ndaAcknowledgementModal.hide();
      updateAuth(authService.getAuthToken(), {
        ...authenticatedUser,
        programCompany: {
          ...authenticatedUser.programCompany,
          trustShareNdaId: createNdaResponse.id,
        },
      });
    } catch (e) {
      ndaAcknowledgementModal.hide();
      setError({ message: 'Something went wrong, Please try again or contact support' });
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/home');
    ndaAcknowledgementModal.hide();
  };

  const handleNdaDownload = async () => {
    window.open(url);
  };

  const modalOnCancel = (...args) => {
    ndaAcknowledgementSteps.move(NDA_ACKNOWLEDGEMENT_STEPS.NDA_REJECTION);
    setModalProps({
      hideSecondary: true,
      primaryLabel: 'Close',
      actionsAlignment: 'center',
    });
    stepModalProps.onSecondary?.(...args); // eslint-disable-line no-unused-expressions
    return true;
  };

  const modalOnConfirm = (...args) => {
    switch (ndaAcknowledgementSteps.currentId) {
      case NDA_ACKNOWLEDGEMENT_STEPS.WELCOME:
        ndaAcknowledgementSteps.move(NDA_ACKNOWLEDGEMENT_STEPS.NDA_AGREEMENT);
        setModalProps({
          primaryLabel: 'I Agree',
          secondaryLabel: 'Decline',
          secondaryButtonVariant: 'secondary',
          enablePrimary: false,
          enableTertiary: true,
          tertiaryLabel: 'Download',
          onTertiary: handleNdaDownload,
          tertiaryButtonVariant: 'quiet',
        });
        break;
      case NDA_ACKNOWLEDGEMENT_STEPS.NDA_AGREEMENT:
        handleNdaAgreementAcceptance();
        break;
      default:
        handleLogout();
    }
    stepModalProps.onPrimary?.(...args); // eslint-disable-line no-unused-expressions
    return true;
  };

  return (
    <StyledModal
      title=""
      showHeader={false}
      primaryLabel="View NDA"
      secondaryLabel="Cancel"
      primaryButtonVariant="primary"
      secondaryButtonVariant="quiet"
      onPrimary={modalOnConfirm}
      onSecondary={modalOnCancel}
      ignoreVerticalPosition
      ignoreSidebar
      showCloseIcon={false}
      hideOnEsc={false}
      {...stepModalProps}
      {...ndaAcknowledgementModal}
    >
      <Step
        step={NDA_ACKNOWLEDGEMENT_STEPS.WELCOME}
        {...ndaAcknowledgementSteps}
      >
        <WelcomeStep
          teamName={currentTeam.name}
          salutationMessage={salutationMessage}
        />
      </Step>
      <Step
        step={NDA_ACKNOWLEDGEMENT_STEPS.NDA_AGREEMENT}
        {...ndaAcknowledgementSteps}
      >
        <NdaAgreementStep
          teamName={currentTeam.name}
          modalState={ndaAcknowledgementModal}
          setModalProps={setModalProps}
          ndaDocumentUrl={url}
          checkboxState={checkboxState}
          email={authenticatedUser.email}
        />
        {isNdaSubmitting && (
          <StyledSpinnerContainer>
            <LoadingSpinner />
          </StyledSpinnerContainer>
        )}
      </Step>
      <Step
        step={NDA_ACKNOWLEDGEMENT_STEPS.NDA_REJECTION}
        {...ndaAcknowledgementSteps}
      >
        <NdaRejectionStep
          modalState={ndaAcknowledgementModal}
          setModalProps={setModalProps}
        />
      </Step>
    </StyledModal>
  );
}

export default NdaAcknowledgementFlow;
