import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { UilExternalLinkAlt } from '@iconscout/react-unicons';
import { Heading, Typography } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';
import Heartbeat from '../../../../components/Heartbeat';
import { BREAKPOINTS, FLAG_IMAGE_BASE_PATH, TRUSTCLOUD_WEBSITE_URL } from '../../../../lib/constants';
import CardContainer from '../../../../components/CardContainer';
import { BrandedLink } from '../../../../components/BrandedLink';

const StyledFlex = styled(Flex)`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    flex-direction: row;
  }
`;

const StyledLogo = styled(Flex)`
  width: 20px;
  height: 20px;

  & > img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const StyledLogoWrapper = styled(Typography)`
  width: 20px;
  height: 20px;

  border: 2px solid ${({ theme }) => theme.palette.silver};
  border-radius: 50%;
`;

const SubprocessortMetadataWrapper = styled(Flex)`
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    flex-direction: row;
    gap: 32px;
  }
`;

const LocationFlag = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 22px;
`;

const FlexTypography = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

function SubprocessorUrlComponent({ subprocessorPageUrl = null, label = null }) {
  if (!subprocessorPageUrl) return null;

  return (
    <FlexTypography
      as={BrandedLink}
      level="8"
      href={subprocessorPageUrl}
      target="_blank"
    >
      {label}
      <UilExternalLinkAlt size={20} />
    </FlexTypography>
  );
}

function SubprocessorMetadata({ selectedSubprocessor }) {
  const theme = useTheme();

  const flagURL = (countryCode) => `${FLAG_IMAGE_BASE_PATH}${countryCode.toLowerCase()}.png`;

  const shouldShowUrlSection =
    selectedSubprocessor.websiteUrl ||
    selectedSubprocessor.vendorTosUrl ||
    selectedSubprocessor.vendorPrivacyPolicyUrl ||
    selectedSubprocessor.vendorSecurityPageUrl;

  return (
    <CardContainer
      direction="column"
      gap="16px"
    >
      <StyledFlex
        justifyContent="space-between"
        direction="column-reverse"
        gap="16px"
      >
        <Typography
          as="p"
          level="9"
        >
          Subprocessor Details
        </Typography>
        <Flex
          gap="8px"
          alignItems="center"
        >
          <Typography
            as="p"
            level="9"
          >
            Continuously monitored with{' '}
            <BrandedLink
              href={TRUSTCLOUD_WEBSITE_URL}
              target="_blank"
            >
              TrustCloud
            </BrandedLink>
          </Typography>
          <Heartbeat strokeColor={theme.palette.jade} />
        </Flex>
      </StyledFlex>
      <Flex
        alignItems="center"
        gap="16px"
      >
        {selectedSubprocessor.logoUrl ? (
          <StyledLogo alignItems="center">
            <img
              src={selectedSubprocessor.logoUrl}
              alt={selectedSubprocessor.name}
            />
          </StyledLogo>
        ) : (
          <StyledLogoWrapper
            as={Flex}
            alignItems="center"
            justifyContent="center"
            level="9"
          >
            {selectedSubprocessor.name[0]}
          </StyledLogoWrapper>
        )}
        <Heading level="5">{selectedSubprocessor.name}</Heading>
      </Flex>
      <SubprocessortMetadataWrapper
        gap="16px"
        direction="column"
      >
        {selectedSubprocessor.group && (
          <Flex
            gap="12px"
            direction="column"
          >
            <Typography
              as="p"
              level="9"
            >
              Department
            </Typography>
            <Typography
              as="p"
              level="8"
            >
              {selectedSubprocessor.group.name}
            </Typography>
          </Flex>
        )}
        {selectedSubprocessor.purpose && (
          <Flex
            gap="8px"
            direction="column"
          >
            <Typography
              as="p"
              level="9"
            >
              Purpose
            </Typography>
            <Typography
              as="p"
              level="8"
            >
              {selectedSubprocessor.purpose}
            </Typography>
          </Flex>
        )}
        {selectedSubprocessor.vendorLocations.length > 0 && (
          <Flex
            gap="8px"
            direction="column"
          >
            <Typography
              as="p"
              level="9"
            >
              Location
            </Typography>
            <Flex
              gap="12px"
              wrap
            >
              {selectedSubprocessor.vendorLocations.map((location) => {
                return (
                  <LocationFlag
                    key={location.value}
                    src={flagURL(location.value)}
                    alt={location.label}
                  />
                );
              })}
            </Flex>
          </Flex>
        )}
      </SubprocessortMetadataWrapper>
      {shouldShowUrlSection && (
        <Flex
          alignItems="center"
          gap="24px"
          wrap
        >
          <SubprocessorUrlComponent
            label="Website"
            subprocessorPageUrl={selectedSubprocessor.websiteUrl}
          />
          <SubprocessorUrlComponent
            label="Terms of Service"
            subprocessorPageUrl={selectedSubprocessor.vendorTosUrl}
          />
          <SubprocessorUrlComponent
            label="Privacy Policy"
            subprocessorPageUrl={selectedSubprocessor.vendorPrivacyPolicyUrl}
          />
          <SubprocessorUrlComponent
            label="Security Page"
            subprocessorPageUrl={selectedSubprocessor.vendorSecurityPageUrl}
          />
        </Flex>
      )}
    </CardContainer>
  );
}

export default SubprocessorMetadata;
