import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Heading, Typography } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';
import Heartbeat from '../../../../components/Heartbeat';
import { BREAKPOINTS, TRUSTCLOUD_WEBSITE_URL } from '../../../../lib/constants';
import { BrandedLink } from '../../../../components/BrandedLink';

// Styled components
const StyledCardHeaderContainer = styled(Flex)`
  padding-top: 2px;

  & > h5 {
    padding-top: 10px;
  }

  & > p {
    padding-top: 4px;
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & > div {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 0;
    }
  }
`;

function ControlCardHeader({ control, specificDescription }) {
  const theme = useTheme();

  return (
    <StyledCardHeaderContainer
      direction="column"
      gap="4px"
    >
      <Flex
        gap="12px"
        direction="column-reverse"
        alignItems="flex-start"
      >
        <Heading
          level="5"
          data-testid="control-name-value"
        >
          {control.categorization.subcategory}
        </Heading>
        <Flex
          gap="8px"
          alignItems="center"
        >
          <Heartbeat strokeColor={theme.palette.jade} />
          <Typography
            level="9"
            as="p"
          >
            Continuously monitored with&nbsp;
            <BrandedLink
              href={TRUSTCLOUD_WEBSITE_URL}
              target="_blank"
            >
              TrustCloud
            </BrandedLink>
          </Typography>
        </Flex>
      </Flex>
      <Typography
        level="8"
        as="p"
      >
        {specificDescription ?? control.controlText}
      </Typography>
    </StyledCardHeaderContainer>
  );
}

export default ControlCardHeader;
