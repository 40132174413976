import React from 'react';
import { useTheme } from '@emotion/react';
import { UilExternalLinkAlt, UilImport, UilPadlock } from '@iconscout/react-unicons';

import { FEATURE_FLAG } from 'lib/featureFlags';
import { useFeatureFlag } from 'lib/state';
import { Spinner } from '../../../../components/Spinner';

const ACTION_ICON_SIZE = 20;

function ResourceActionIcon({ canDownload = false, canView = false, isDownloading, user = null }) {
  const theme = useTheme();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  const shouldShowRequestAccessButton = !isRequestAccessHidden && !canDownload && !canView && !user;

  // Handles the case when a document can be downloaded but not viewed
  if (canDownload) {
    return isDownloading ? (
      <div>
        <Spinner fill={theme.components.header.primaryCTAButton.background} />
      </div>
    ) : (
      <div>
        <UilImport
          size={ACTION_ICON_SIZE}
          fill={theme.components.header.primaryCTAButton.background}
        />
      </div>
    );
  }

  // Handles the case when a document can be viewed but not downloaded
  if (canView && !canDownload) {
    return (
      <div>
        <UilExternalLinkAlt
          size={ACTION_ICON_SIZE}
          fill={theme.components.header.primaryCTAButton.background}
        />
      </div>
    );
  }

  // Handles the case when a document can neither be downloaded nor viewed and the user has not authenticated
  if (shouldShowRequestAccessButton) {
    return (
      <div>
        <UilPadlock
          size={ACTION_ICON_SIZE}
          fill={theme.components.header.primaryCTAButton.background}
        />
      </div>
    );
  }

  return null;
}

export default ResourceActionIcon;
