import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { UilExternalLinkAlt, UilImport, UilKeySkeleton } from '@iconscout/react-unicons';
import { flat, Heading, Typography } from '@kintent/glide';
import React, { useState } from 'react';
import { Link as BaseLink, useNavigation } from 'react-navi';

import { FEATURE_FLAG } from 'lib/featureFlags';
import CardContainer from '../../../../components/CardContainer';
import DateTime from '../../../../components/DateTime';
import { Flex } from '../../../../components/Flex';
import Heartbeat from '../../../../components/Heartbeat';
import { Spinner } from '../../../../components/Spinner';
import { SpaceAround } from '../../../../design-system';
import {
  BREAKPOINTS,
  CERTIFICATION_STATUS,
  CERTIFICATION_STATUS_DISPLAY_NAME_MAP,
  getCertificationCardTitle,
  getCertificationLogo,
  getCertificationLogoSrcSet,
  PAGE_ROUTES,
  TRUSTCLOUD_WEBSITE_URL,
} from '../../../../lib/constants';
import {
  useAuthService,
  useCertificationDownload,
  useCertificationViewedActivity,
  useFeatureFlag,
  useRequestAccessRedirectionUrl,
} from '../../../../lib/state';
import {
  canDownloadCertification,
  canViewCertification,
  downloadFileFromAPI,
  getCertificationReportDisplayName,
  getDefaultCertificationDescription,
} from '../../../../lib/utils';
import { BrandedLink } from '../../../../components/BrandedLink';
import { GlideButton } from '../../../../components/GlideButton';

// Constants.
const EMPTY_OBJECT = {};

const StyledFlex = styled(Flex)`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    flex-direction: row;
  }
`;

const CertificationMetadataWrapper = styled(Flex)`
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    flex-direction: row;
    gap: 48px;
  }
`;

const CertificationStatusLabel = styled(Typography)`
  padding: 0 12px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => flat(theme.color.system.positive, '40%')};
  background-color: ${({ theme }) => flat(theme.color.system.positive, '10%')};
  font-weight: 500;
`;

const WrappedHeading = styled(Heading)`
  word-break: break-word;
`;

const StyledLogo = styled(Flex)`
  width: 20px;
  height: 20px;

  & > img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const StyledHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => flat(theme.color.system.gray, '100%')};
  line-height: 30px;

  & > span {
    font-weight: 700;
  }
`;

const shouldShowContent = (...conditions) => conditions.every(Boolean);

const getDescription = ({ selectedCertification, currentTeam, selectedDocument }) => {
  if (selectedDocument) {
    return selectedDocument?.description;
  }
  return (
    selectedCertification.description ??
    getDefaultCertificationDescription(
      currentTeam.name,
      selectedCertification?.certification?.shortName,
      selectedCertification?.status,
      selectedCertification?.subtype
    )
  );
};

const getStatus = ({ selectedCertification, selectedDocument }) => {
  const selectedArtifact = selectedDocument ?? selectedCertification;
  if (selectedArtifact && selectedArtifact?.status) {
    return (
      <CertificationStatusLabel
        level="8"
        strength="100%"
      >
        {CERTIFICATION_STATUS_DISPLAY_NAME_MAP[selectedArtifact.status]}
      </CertificationStatusLabel>
    );
  }
  return null;
};

const getDateExpected = ({ selectedCertification, selectedDocument }) => {
  const selectedArtifact = selectedDocument ?? selectedCertification;
  if (selectedArtifact?.certificationDate) {
    return (
      <Flex
        gap="16px"
        direction="column"
      >
        <Typography
          as="p"
          level="9"
        >
          {selectedArtifact?.status === CERTIFICATION_STATUS.IN_PROGRESS ? 'Date Expected' : 'Date Achieved'}
        </Typography>
        <Heading
          as="p"
          level="8"
        >
          <DateTime
            date={new Date(selectedArtifact.certificationDate)}
            shortMonth
            includeYear
          />
        </Heading>
      </Flex>
    );
  }
  return null;
};

const getAuditFirmName = ({ selectedCertification, selectedDocument }) => {
  const selectedArtifact = selectedDocument ?? selectedCertification;
  if (selectedArtifact?.certificationDate) {
    return (
      selectedArtifact?.auditNameFirm && (
        <Flex
          gap="16px"
          direction="column"
        >
          <Typography
            as="p"
            level="9"
          >
            Name of Audit Firm
          </Typography>
          <WrappedHeading
            as={selectedArtifact?.auditFirmWebsite ? BaseLink : 'p'}
            level="8"
            href={selectedArtifact?.auditFirmWebsite ?? null}
            target="_blank"
          >
            {selectedArtifact.auditNameFirm}
          </WrappedHeading>
        </Flex>
      )
    );
  }
  return null;
};

const CertificationMetadataHeading = ({ selectedCertification = EMPTY_OBJECT, selectedDocument = EMPTY_OBJECT }) => {
  const theme = useTheme();

  const { subtype = '', certification: { shortName } = EMPTY_OBJECT } = selectedCertification;
  return (
    <>
      <StyledFlex
        justifyContent="space-between"
        direction="column-reverse"
        gap="16px"
      >
        <StyledHeading level="5">
          <StyledLogo alignItems="center">
            <img
              src={getCertificationLogo(shortName, subtype)}
              srcSet={getCertificationLogoSrcSet(shortName, subtype)}
              alt="Certification Logo"
            />
          </StyledLogo>
          <Typography level="3">{getCertificationCardTitle(shortName, subtype)}</Typography>
        </StyledHeading>
        <Flex
          gap="8px"
          alignItems="center"
        >
          <Typography
            as="p"
            level="9"
          >
            Continuously monitored with{' '}
            <BrandedLink
              href={TRUSTCLOUD_WEBSITE_URL}
              target="_blank"
            >
              TrustCloud
            </BrandedLink>
          </Typography>
          <Heartbeat strokeColor={theme.palette.jade} />
        </Flex>
      </StyledFlex>
      {selectedDocument && <Typography level="4">{selectedDocument.title}</Typography>}
    </>
  );
};

function CertificationMetadata({ selectedCertification = EMPTY_OBJECT, selectedDocument }) {
  const artifact = selectedDocument ?? selectedCertification;

  const { authenticatedUser, currentTeam } = useAuthService();
  const downloadCertification = useCertificationDownload(currentTeam.id, artifact.id);
  const { trackCertificationView } = useCertificationViewedActivity();
  const [isDownloading, setIsDownloading] = useState(false);
  const { requestAccessRedirectionUrl } = useRequestAccessRedirectionUrl();
  const { navigate } = useNavigation();
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  const canDownloadFile = canDownloadCertification(
    artifact.trustShareAccessLevel,
    artifact.filename,
    authenticatedUser
  );

  const canViewFile = canViewCertification(
    artifact.trustShareAccessLevel,
    artifact.certificationUrl,
    authenticatedUser
  );

  const certificationName = getCertificationReportDisplayName(artifact.certification.shortName, artifact.subtype);

  const handleCertificationDownload = async () => {
    try {
      setIsDownloading(true);
      await downloadFileFromAPI(() => downloadCertification(), artifact.filename);
      setIsDownloading(false);
    } catch (error) {
      // TODO - We don't have a way to handle this error for now
      setIsDownloading(false);
    }
  };

  const handleCertificationClick = () => {
    trackCertificationView(artifact.id);
    window.open(artifact.certificationUrl);
  };

  const handleRequestAccessClick = () => {
    navigate(
      requestAccessRedirectionUrl === PAGE_ROUTES.REQUEST_ACCESS
        ? `${PAGE_ROUTES.REQUEST_ACCESS}?artifact=${encodeURIComponent(certificationName)}&type=document`
        : requestAccessRedirectionUrl
    );
  };

  const shouldShowRequestAccessToView = shouldShowContent(
    !!(artifact.filename || artifact.certificationUrl),
    !isRequestAccessHidden,
    !canDownloadFile,
    !canViewFile,
    !authenticatedUser
  );
  const shouldShowRequestAccessToDownload = shouldShowContent(!isRequestAccessHidden, !canDownloadFile, canViewFile);
  const shouldShowViewButton = shouldShowContent(canViewFile, !canDownloadFile);

  return (
    <CardContainer
      direction="column"
      gap="16px"
    >
      <CertificationMetadataHeading
        selectedCertification={selectedCertification}
        selectedDocument={selectedDocument}
      />
      <SpaceAround
        as={Typography}
        level="body"
        bottom="8px"
      >
        {getDescription({ selectedCertification, currentTeam, selectedDocument })}
      </SpaceAround>
      <CertificationMetadataWrapper
        as={SpaceAround}
        bottom="8px"
        gap="16px"
        direction="column"
      >
        {artifact.status && (
          <Flex
            gap="12px"
            direction="column"
          >
            <Typography
              as="p"
              level="9"
            >
              Status
            </Typography>
            {getStatus({ selectedCertification, selectedDocument })}
          </Flex>
        )}
        {getDateExpected({ selectedCertification, selectedDocument })}
        {getAuditFirmName({ selectedCertification, selectedDocument })}
      </CertificationMetadataWrapper>
      {(canDownloadFile || canViewFile) && (
        <>
          {canDownloadFile && (
            <GlideButton
              disabled={isDownloading}
              onClick={handleCertificationDownload}
              icon={isDownloading ? <Spinner size={20} /> : <UilImport size={20} />}
            >
              Download
            </GlideButton>
          )}
          {shouldShowViewButton && (
            <GlideButton
              onClick={handleCertificationClick}
              icon={<UilExternalLinkAlt size={16} />}
            >
              View
            </GlideButton>
          )}
        </>
      )}

      {!canViewFile && shouldShowRequestAccessToDownload && (
        <GlideButton
          onClick={handleRequestAccessClick}
          icon={<UilKeySkeleton size={16} />}
        >
          Request access to download
        </GlideButton>
      )}

      {shouldShowRequestAccessToView && (
        <GlideButton
          onClick={handleRequestAccessClick}
          icon={<UilKeySkeleton size={16} />}
        >
          Request access to view
        </GlideButton>
      )}
    </CardContainer>
  );
}

export default CertificationMetadata;
