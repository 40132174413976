// Package modules
import React, { Suspense, useEffect, useState } from 'react';
import { useCurrentRoute, useNavigation, View } from 'react-navi';
import { ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';

// Local modules
import CustomizeBanner from 'app/trust-share/home/components/CustomizeTrustshareBanner';
import { Flex } from './Flex';
import { NavigationBar, withNavigationBar } from './NavigationBar';
import { ErrorBoundary } from './ErrorBoundary';
import { AppFooter } from './AppFooter';
import { MediaQueriesProvider } from './MediaQueriesProvider';
import { useAuthService, useCustomerTheme, useProgramContent } from '../lib/state';
import { BREAKPOINTS, PATHS_VISIBLE_FOR_PRIVATE_SITE, TRUST_SHARE_ACCESS_LEVELS, USER_TYPE } from '../lib/constants';
import NdaAcknowledgementFlow from './NdaAcknowledgementFlow/NdaAcknowledgementFlow';

// Styled components
const ApplicationContainer = styled(Flex)`
  min-height: 100vh;
  background-image: radial-gradient(
    200.9% 240.01% at 0% 0%,
    rgba(230, 230, 230, 0.05) 4.17%,
    #f5f5f5 24.48%,
    rgba(255, 255, 255, 0.1) 48.44%,
    rgba(245, 245, 245, 0.05) 74.48%,
    rgba(230, 230, 230, 0.05) 95.83%
  );
`;

const HomeContainer = styled(Flex, {
  shouldForwardProp(props) {
    return !['withAppBackground', 'backgroundColor'].includes(props);
  },
})`
  min-height: 100vh;
  background-color: ${({ theme, withAppBackground, backgroundColor }) =>
    withAppBackground ? backgroundColor || theme.palette.bone : 'transparent'};
  overflow-x: auto;
  background-image: radial-gradient(
    200.9% 240.01% at 0% 0%,
    rgba(230, 230, 230, 0.05) 4.17%,
    #f5f5f5 24.48%,
    rgba(255, 255, 255, 0.1) 48.44%,
    rgba(245, 245, 245, 0.05) 74.48%,
    rgba(230, 230, 230, 0.05) 95.83%
  );
`;

const StyledMain = styled('main', {
  shouldForwardProp(props) {
    return !['paddingTop'].includes(props);
  },
})`
  flex: 1;
  padding-top: 6rem;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      padding-top: 10rem;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      padding-top: ${({ paddingTop }) => paddingTop}rem;
    }
  }
`;

const BlurredHeader = styled.div`
  position: fixed;
  // BlurredHeader should lie just beneath the navigation bar (z-index of 1000), hence the 999 value here.
  z-index: 999;
  left: 0;
  right: 0;

  // Same width as the navigation bar
  width: calc(100% - 4rem);
  height: 20px;

  margin: 0 auto;

  -webkit-backdrop-filter: blur(10px) contrast(0.99);
  backdrop-filter: blur(10px) contrast(0.99);

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    & {
      width: calc(100% - 6rem);
    }
  }
`;

const Container = styled(Flex)`
  max-width: 1024px;
  margin: 0 auto;
`;

const shouldRedirectToHome = (pathname, trustShareAccessLevel, authenticatedUser) => {
  if (authenticatedUser === null && trustShareAccessLevel === TRUST_SHARE_ACCESS_LEVELS.PRIVATE) {
    const basePath = `/${pathname.split('/')[1]}`;
    return !PATHS_VISIBLE_FOR_PRIVATE_SITE.includes(basePath);
  }
  return false;
};

export const FullPageLayout = withNavigationBar(({ withAppBackground, backgroundColor }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const [{ headerStyleConfig }] = useProgramContent();
  const customerTheme = useCustomerTheme(headerStyleConfig);
  const {
    currentTeam: { trustShareAccessLevel },
    authenticatedUser,
  } = useAuthService();
  const {
    url: { pathname },
  } = useCurrentRoute();
  const { navigate } = useNavigation();

  const shouldShowNdaFlow = authenticatedUser && authenticatedUser.userType === USER_TYPE.TEMPORARY_GUEST;

  useEffect(() => {
    if (shouldRedirectToHome(pathname, trustShareAccessLevel, authenticatedUser)) {
      navigate('/home');
    } else {
      setShouldRender(true);
    }
  }, [pathname, trustShareAccessLevel, authenticatedUser]);

  if (!shouldRender) {
    return null;
  }

  return (
    <ThemeProvider theme={customerTheme}>
      <MediaQueriesProvider>
        <HomeContainer
          direction="column"
          withAppBackground={withAppBackground}
          backgroundColor={backgroundColor}
        >
          <ErrorBoundary>
            <Suspense>
              <BlurredHeader />
              <NavigationBar />
              {shouldShowNdaFlow && <NdaAcknowledgementFlow />}
              <StyledMain paddingTop={12}>
                <View />
              </StyledMain>
              <AppFooter />
            </Suspense>
          </ErrorBoundary>
        </HomeContainer>
      </MediaQueriesProvider>
    </ThemeProvider>
  );
});

export const AppLayout = withNavigationBar(() => {
  const [shouldRender, setShouldRender] = useState(false);
  const [{ headerStyleConfig }] = useProgramContent();
  const customerTheme = useCustomerTheme(headerStyleConfig);
  const {
    currentTeam: { trustShareAccessLevel },
    authenticatedUser,
  } = useAuthService();
  const {
    url: { pathname },
  } = useCurrentRoute();
  const { navigate } = useNavigation();

  const shouldShowNdaFlow = authenticatedUser && authenticatedUser.userType === USER_TYPE.TEMPORARY_GUEST;

  useEffect(() => {
    if (shouldRedirectToHome(pathname, trustShareAccessLevel, authenticatedUser)) {
      navigate('/home');
    } else {
      setShouldRender(true);
    }
  }, [pathname, trustShareAccessLevel, authenticatedUser]);

  if (!shouldRender) {
    return null;
  }

  return (
    <ThemeProvider theme={customerTheme}>
      <MediaQueriesProvider>
        <ApplicationContainer
          direction="column"
          gap="3rem"
        >
          <ErrorBoundary>
            <Suspense>
              <BlurredHeader />
              <NavigationBar />
              {shouldShowNdaFlow && <NdaAcknowledgementFlow />}
              {authenticatedUser?.id && <CustomizeBanner />}
              <StyledMain paddingTop={10}>
                <Container
                  direction="column"
                  gap="3rem"
                >
                  <View />
                </Container>
              </StyledMain>
            </Suspense>
          </ErrorBoundary>
          <AppFooter />
        </ApplicationContainer>
      </MediaQueriesProvider>
    </ThemeProvider>
  );
});
