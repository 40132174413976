import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-navi';
import { useTheme } from '@emotion/react';
import { AnimatePresence, motion } from 'framer-motion';
import { Badge, base, flat, Heading, translucent, Typography } from '@kintent/glide';
import { UilSlidersVAlt } from '@iconscout/react-unicons';

import { Flex } from 'components/Flex';
import { useContentVisibility, useControlList, useProgramContent } from 'lib/state';
import Heartbeat from 'components/Heartbeat';
import { filterForAdoptedControls, transformControlsByCategory } from 'lib/utils';
import { BREAKPOINTS } from 'lib/constants';
import PulsatingDot, { PULSATING_DOT_VARIANTS } from 'components/PulsatingDot';
import { useDeviceResolution } from 'lib/hooks';
import HomePageContainer from './HomePageContainer';

// Styled components
const ControlsLayoutContainer = styled(HomePageContainer)`
  margin-top: 72px;
`;

const ControlsByCategoryGrid = styled.div`
  -moz-column-count: 1;
  column-count: 1;
  max-width: 328px;
  -moz-column-gap: 32px;
  column-gap: 32px;
  -moz-column-fill: balance;
  column-fill: balance;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    -moz-column-count: 2;
    column-count: 2;
    max-width: 100%;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    -moz-column-count: 3;
    column-count: 3;
    max-width: 100%;
  }

  & > * {
    display: inline-block;
    margin: 0 0 32px;
    page-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid;
    width: 100%;
  }

  /* This applies padding to each child div so there is a gap between
  * the grid container and the last control item
  */
  & > div {
    padding-bottom: 16px;
  }
`;

const ControlCategoryLabel = styled(Heading)`
  padding: 12px 0;
  text-align: left;
`;

const ControlCategoryGridItem = styled.div`
  padding: 20px 24px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '20%')};
  background-color: white;
`;

const ControlRowWrapper = styled(motion(Flex))`
  padding: 0px 10px;
`;

// This component is rendered as a Link so the entire container is clickable and has pointer events
const ControlRowWrapperLink = styled(motion(Link))`
  text-decoration: none;
  padding: 2px 2px;
  & > span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
  &:hover {
    & > span {
      color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
      text-decoration: underline;
    }
  }
  transition: color 250ms ease-in;
`;

const StyledFlex = styled(Flex)`
  width: 22px;
  height: 22px;
`;

const StyledControlCount = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray, '40%')};
`;

const StyledBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
`;

function ControlItem({ control, shouldHideLink }) {
  const { isDesktop } = useDeviceResolution();
  const theme = useTheme();
  const [isHover, setHover] = useState(false);

  // Ensure a memoized version of the setHover is returned to be consumed during the render cycle
  const toggleHoverState = useCallback(() => {
    setHover((hover) => !hover);
  }, [setHover]);

  const controlProps = {};
  if (!shouldHideLink) {
    controlProps.as = ControlRowWrapperLink;
    controlProps.href = `/controls/${encodeURIComponent(control.customShortName ?? control.shortName)}`;
  }

  return (
    <ControlRowWrapper
      alignItems="center"
      justifyContent="space-between"
      onHoverStart={toggleHoverState}
      onHoverEnd={toggleHoverState}
      {...controlProps}
    >
      <Typography
        as="span"
        level="8"
      >
        <UilSlidersVAlt size="16px" /> {control.categorization.subcategory}
      </Typography>
      <StyledFlex
        alignItems="center"
        justifyContent="center"
      >
        <AnimatePresence exitBeforeEnter>
          {isDesktop ? (
            <AnimatePresence exitBeforeEnter>
              {!isHover ? (
                <PulsatingDot
                  key="pulse-animation"
                  exit="exit"
                  variants={PULSATING_DOT_VARIANTS}
                />
              ) : (
                <Heartbeat
                  key="heartbeat-animation"
                  strokeColor={theme.components.header.primaryCTAButton.background}
                />
              )}
            </AnimatePresence>
          ) : (
            <Heartbeat
              key="heartbeat-animation"
              strokeColor={theme.components.header.primaryCTAButton.background}
            />
          )}
        </AnimatePresence>
      </StyledFlex>
    </ControlRowWrapper>
  );
}

function ControlsByCategory({ controlsByCategory, shouldHideLinkForControlDetails }) {
  const sortedControlsBySubcategory = controlsByCategory.sort((a, b) =>
    a.categorization.subcategory > b.categorization.subcategory ? 1 : -1
  );
  return sortedControlsBySubcategory.map((control) => (
    <ControlItem
      key={control.id}
      control={control}
      shouldHideLink={shouldHideLinkForControlDetails}
    />
  ));
}

function DashboardControlsSection() {
  const theme = useTheme();
  const [{ isControlVisible }] = useProgramContent();
  const [controls] = useControlList();
  const adoptedControls = filterForAdoptedControls(controls);

  const { shouldHideControlDetail: shouldHideLinkForControlDetails } = useContentVisibility();

  if (!isControlVisible || adoptedControls.length === 0) {
    return null;
  }

  const adoptedControlsByCategory = transformControlsByCategory(adoptedControls);

  return (
    <ControlsLayoutContainer as="section">
      <Flex
        direction="column"
        gap="16px"
      >
        <Flex
          gap="16px"
          alignItems="center"
        >
          <StyledBadge variant="primary">{adoptedControls.length}</StyledBadge>
          <Heading level="3">Continuously Monitored Controls</Heading>
          <Heartbeat strokeColor={flat(theme.color.brand.primary, '100%')} />
        </Flex>
      </Flex>
      <Flex gap="16px">
        <ControlsByCategoryGrid>
          {Object.keys(adoptedControlsByCategory)
            .sort()
            .map((category) => {
              const controlsByCategory = adoptedControlsByCategory[category];
              if (controlsByCategory.length === 0) return null;
              return (
                <ControlCategoryGridItem key={category}>
                  <Flex
                    direction="column"
                    gap="8px"
                  >
                    <ControlCategoryLabel level="6">
                      {category} <StyledControlCount level="6">· {controlsByCategory.length}</StyledControlCount>
                    </ControlCategoryLabel>
                    <ControlsByCategory
                      controlsByCategory={controlsByCategory}
                      shouldHideLinkForControlDetails={shouldHideLinkForControlDetails}
                    />
                  </Flex>
                </ControlCategoryGridItem>
              );
            })}
        </ControlsByCategoryGrid>
      </Flex>
    </ControlsLayoutContainer>
  );
}

export default DashboardControlsSection;
