import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { flat, translucent, Typography } from '@kintent/glide';
import { Link, useCurrentRoute } from 'react-navi';

import { getCertificationCardTitle, getCertificationLogo, getCertificationLogoSrcSet } from '../../../../lib/constants';
import { Flex } from '../../../../components/Flex';
import { ReactComponent as Ellipse } from '../../../../assets/ellipse-15.svg';

const CertificationGroupContainer = styled(Flex)`
  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  border-radius: 8px;
  padding: 24px;
  background: white;
  box-shadow: 0px 2px 2px 0px
    ${({ theme }) => `${theme.color.shadow.drop} ${translucent(theme.color.shadow.drop, '10%')}`};
  max-width: 240px;
`;

const ArtifactWrapper = styled.div`
  min-width: 192px;

  & > a {
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
  }

  & > a.active {
    & > span {
      color: ${({ theme }) => flat(theme.color.brand.primary, '100%')};
    }
  }

  & > div > a {
    display: flex;
    align-items: center;
    gap: 12px;
    text-decoration: none;
  }

  & > div > a.active {
    & > p {
      color: ${({ theme }) => flat(theme.color.brand.primary, '100%')};
    }
    & > svg > circle {
      fill: ${({ theme }) => flat(theme.color.brand.primary, '100%')};
    }
  }
`;

const StyledLogo = styled(Flex)`
  width: 16px;
  height: 16px;

  & > img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const MultipleDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: start;
  padding-top: 16px;
`;

const DocumentsContainer = styled(Link)`
  margin: 0 8px;
  & > svg {
    min-width: 4px;
    min-height: 4px;
  }
  & > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  max-width: 140px;
`;

function MultipleDocuments({ documents, certification, subtype, isCertificationSelected }) {
  const urlParams = new URLSearchParams(window.location.search);
  const documentId = urlParams.get('documentId');

  const sortedDocuments = useMemo(
    () =>
      [...documents].sort((a, b) => {
        const aField = a.title ?? a.filename;
        const bField = b.title ?? b.filename;
        return aField.localeCompare(bField, 'en', { numeric: true });
      }),
    [documents]
  );

  return (
    <MultipleDocumentsContainer>
      {sortedDocuments.map((document) => {
        const isDocumentSelected = isCertificationSelected && document.id === documentId;
        return (
          <DocumentsContainer
            key={document.id}
            className={isDocumentSelected ? 'active' : ''}
            href={`/certifications/${encodeURIComponent(`${certification.shortName}${subtype || ''}`)}?documentId=${
              document.id
            }`}
          >
            <Ellipse />
            <Typography
              as="p"
              level="9"
            >
              {document.title}
            </Typography>
          </DocumentsContainer>
        );
      })}
    </MultipleDocumentsContainer>
  );
}

function CertificationLeftPanelV2({ selectedCertification, teamCertifications, onCertificationClick }) {
  const {
    data: { slug },
  } = useCurrentRoute();
  if (selectedCertification == null) {
    // redirect to /certifications
    window.location.href = '/certifications';
    return null;
  }
  const { certification: { shortName } = {} } = selectedCertification;

  return (
    <CertificationGroupContainer
      direction="column"
      gap="20px"
      alignItems="flex-start"
      justifyContent="center"
    >
      {teamCertifications.map((teamCertification) => {
        const { id, certification, subtype = '' } = teamCertification;
        const isCertificationSelected =
          (slug || `${shortName}${selectedCertification?.subtype || ''}`) === `${certification.shortName}${subtype}`;
        return (
          <ArtifactWrapper
            gap="12px"
            key={id}
          >
            <Link
              onClick={() => onCertificationClick(teamCertification)}
              className={isCertificationSelected ? 'active' : ''}
              href={`/certifications/${encodeURIComponent(`${certification.shortName}${subtype || ''}`)}`}
            >
              <StyledLogo alignItems="center">
                <img
                  src={getCertificationLogo(certification.shortName, subtype)}
                  srcSet={getCertificationLogoSrcSet(certification.shortName, subtype)}
                  alt="Certification Logo"
                />
              </StyledLogo>
              <Typography
                strength="80%"
                level="8"
              >
                {getCertificationCardTitle(certification.shortName, subtype)}
              </Typography>
            </Link>
            {teamCertification.children?.length > 0 && (
              <MultipleDocuments
                documents={teamCertification.children}
                certification={certification}
                subtype={subtype}
                isCertificationSelected={isCertificationSelected}
              />
            )}
          </ArtifactWrapper>
        );
      })}
    </CertificationGroupContainer>
  );
}

export default CertificationLeftPanelV2;
