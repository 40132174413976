// Package modules
import React, { useState } from 'react';
import { Link } from 'react-navi';
import styled from '@emotion/styled';
import { Box } from 'reakit';
import { useTheme } from '@emotion/react';
import { UilImport as DownloadIcon, UilExternalLinkAlt as ExternalLinkIcon } from '@iconscout/react-unicons';
import { Heading, Typography } from '@kintent/glide';

// Local modules
import { Button } from '../design-system';
import { Flex } from './Flex';
import { Spinner } from './Spinner';
import { useAuthService, useCertificationDownload, useCertificationViewedActivity } from '../lib/state';
import {
  canDownloadCertification,
  canViewCertification,
  downloadFileFromAPI,
  getDefaultCertificationDescription,
  hexOpacity,
} from '../lib/utils';
import {
  BREAKPOINTS,
  CERTIFICATION_STATUS,
  getCertificationCardTitle,
  getCertificationLogo,
  getCertificationLogoSrcSet,
} from '../lib/constants';

// Styled components
const ListCard = styled(Flex)`
  width: 100%;
  padding: 1rem 1.25rem;
  background-color: ${({ theme }) => theme.palette.bone};
  border-radius: ${({ theme }) => theme.radius.xl};
  box-shadow: -5px -5px 10px 0px ${({ theme }) => hexOpacity(theme.palette.white, 0.8)},
    5px 5px 15px 0px ${({ theme }) => hexOpacity(theme.palette.stone, 0.5)};

  & > p.certification-title,
  p.certification-status {
    text-align: center;
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      gap: 1.5rem;
    }
  }
`;

const CertificationLogoWrapper = styled(Box)`
  align-self: center;

  width: 64px;
  height: 64px;

  & > img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

const CardActions = styled(Typography)`
  margin-top: auto;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      text-align: right;
    }
  }
`;

const StyledLinkButton = styled(Button)`
  text-decoration: none;
`;

export function CertificationListCard({
  teamCertificationId,
  filename,
  subtype,
  status,
  shortName,
  certificationUrl,
  description = null,
  accessLevel,
}) {
  const [isDownloading, setDownloading] = useState(false);
  const theme = useTheme();
  const { currentTeam, authenticatedUser } = useAuthService();
  const { trackCertificationView } = useCertificationViewedActivity();

  const downloadCertification = useCertificationDownload(currentTeam.id, teamCertificationId);

  const handleDownloadCertification = async (certificationFilename) => {
    setDownloading(true);
    await downloadFileFromAPI(() => downloadCertification(), certificationFilename);
    setDownloading(false);
  };

  const handleCertificationClick = () => {
    trackCertificationView(teamCertificationId);
    window.open(certificationUrl);
  };

  const canDownloadReport = canDownloadCertification(accessLevel, filename, authenticatedUser);
  const canViewReport = canViewCertification(accessLevel, certificationUrl, authenticatedUser);

  return (
    <ListCard
      direction="column"
      gap="1rem"
    >
      <Heading
        as="p"
        level="6"
        className="certification-title"
      >
        {getCertificationCardTitle(shortName, subtype)}
      </Heading>
      <CertificationLogoWrapper>
        <img
          src={getCertificationLogo(shortName, subtype)}
          srcSet={getCertificationLogoSrcSet(shortName, subtype)}
          alt="Certification Logo"
        />
      </CertificationLogoWrapper>
      {status === CERTIFICATION_STATUS.IN_PROGRESS && (
        <Heading
          as="p"
          level="8"
          className="certification-status"
        >
          In Progress
        </Heading>
      )}
      <Flex
        direction="column"
        gap="0.75rem"
      >
        <Typography
          as="p"
          level="8"
        >
          {description ?? getDefaultCertificationDescription(currentTeam.name, shortName, status, subtype)}
        </Typography>
      </Flex>
      {(canDownloadReport || canViewReport) && (
        <CardActions align="center">
          {canDownloadReport && (
            <Button
              variant="secondary"
              onClick={() => handleDownloadCertification(teamCertificationId, filename)}
              startIcon={
                isDownloading ? (
                  <Spinner
                    size={14}
                    color={theme.palette.navy}
                  />
                ) : (
                  <DownloadIcon size={16} />
                )
              }
            >
              Download
            </Button>
          )}
          {canViewReport && !canDownloadReport && (
            <StyledLinkButton
              as={Link}
              variant="secondary"
              onClick={handleCertificationClick}
              startIcon={<ExternalLinkIcon size={16} />}
            >
              View
            </StyledLinkButton>
          )}
        </CardActions>
      )}
    </ListCard>
  );
}
