import { base, flat, translucent, Typography } from '@kintent/glide';
import styled from '@emotion/styled';

import { Flex } from '../../../../components/Flex';
import { Avatar } from '../../../../components/Avatar';

export const FlexItem = styled(Flex)`
  width: 240px;
  padding: 20px;
  border: 1px solid ${({ theme }) => flat(theme.color.system.gray, '20%')};
  border-radius: 8px;
  box-shadow: ${base.boxShadow.drop} ${({ theme }) => translucent(theme.color.shadow.drop, '10%')};
  background-color: white;
  display: flex;
  align-items: center;
`;

export const LeaderAvatar = styled(Avatar)`
  border: 2px solid ${({ theme }) => flat(theme.color.category.forest, '40%')};
  cursor: default;
  min-height: 60px;
`;

export const LabelTypography = styled(Typography)`
  text-align: center;
`;
