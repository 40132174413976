import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-navi';
import {
  unstable_Form as BaseForm,
  Checkbox,
  unstable_FormSubmitButton as FormSubmitButton,
  useCheckboxState,
  unstable_useFormState as useFormState,
} from 'reakit';

import { AuthenticationCard } from 'app/user/components/AuthFlowComponents';
import { FormContext, FormInput, FormMessage } from '../../design-system';
import { CardTitleLabel } from '../../components/PageElements';
import { Flex } from '../../components/Flex';
import { TermsAndPrivacyPolicy } from '../../components/TermsAndPrivacyPolicy';
import { CREATE_ACCOUNT_MESSAGES } from '../../lib/errors/ApiError';
import { useAuthService, useFeatureFlag } from '../../lib/state';
import { hasPasswordBeenBreached } from '../../lib/utils';
import { EMAIL_REGEX, RESTRICTED_EMAIL_DOMAINS, USER_TYPE } from '../../lib/constants';
import { FEATURE_FLAG } from '../../lib/featureFlags';
import { api } from '../../lib/api';
import CreateAccountMessage from './CreateAccountMessage';
import { GlideButton } from '../../components/GlideButton';

// Styled components
const UserDetailFormGrid = styled.div`
  display: grid;
  gap: 30px;

  padding-left: 88px;
  padding-right: 88px;
  padding-bottom: 24px;
`;

const Heading = styled(CardTitleLabel)`
  margin-bottom: 44px;
`;

const CreateAccountWithUserDetails = ({ handleBack }) => {
  const { currentTeam } = useAuthService();
  const [isPasswordBreached, setPasswordBreached] = useState(false);
  const [formSubmissionMessage, setFormSubmissionMessage] = useState(null);
  const passwordInputRef = useRef();
  const checkbox = useCheckboxState({ state: false });

  const isSelfServeRestrictedEmailDomainsEnabled = useFeatureFlag(FEATURE_FLAG.SELF_SERVE_RESTRICTED_EMAIL_DOMAINS);
  const restrictedEmailMessage = (
    <>
      Oops! The email domain you’re using to access TrustShare is restricted; please ensure the email belongs to a
      supported domain or contact our support team
      {currentTeam?.contactUsEmail != null ? (
        <a href={`mailto:${currentTeam.contactUsEmail}`}> at {currentTeam.contactUsEmail}</a>
      ) : (
        ''
      )}{' '}
      for assistance.
    </>
  );

  const userDetailsForm = useFormState({
    onSubmit: async ({ firstName, lastName, email, password }) => {
      try {
        await api.user.create({
          name: `${firstName.trim()} ${lastName.trim()}`,
          email: email.trim(),
          password: password.trim(),
          teamId: currentTeam.id,
          userType: USER_TYPE.TEMPORARY_GUEST,
        });
        setFormSubmissionMessage(CREATE_ACCOUNT_MESSAGES.ACCOUNT_CREATED);
      } catch (e) {
        if (Object.values(CREATE_ACCOUNT_MESSAGES).includes(e?.sourceError.error)) {
          setFormSubmissionMessage(e?.sourceError.error);
        } else {
          setFormSubmissionMessage(CREATE_ACCOUNT_MESSAGES.SOMETHING_WENT_WRONG);
        }
      }
    },
    onValidate: async ({ firstName, lastName, email, password }) => {
      const errors = {};

      if (firstName.trim().length === 0) {
        errors.firstName = 'Please enter your first name';
      }

      if (lastName.trim().length === 0) {
        errors.lastName = 'Please enter your last name';
      }

      if (!EMAIL_REGEX.test(email)) {
        errors.email = 'Please enter a valid email address';
      }

      if (isSelfServeRestrictedEmailDomainsEnabled) {
        const [, emailDomain = null] = email.split('@');
        const doesEmailDomainMatch = RESTRICTED_EMAIL_DOMAINS.some((domain) => domain === emailDomain);
        if (doesEmailDomainMatch) {
          errors.email = restrictedEmailMessage;
        }
      }

      if (passwordInputRef.current.checkValidity() === false || password.length < 8) {
        errors.password = 'Please enter at least 8 characters';
      } else {
        setPasswordBreached(false);
        const hasBeenBreached = await hasPasswordBeenBreached(password.trim());
        if (hasBeenBreached) {
          setPasswordBreached(true);
          errors.password = (
            <>
              This password has previously been exposed in a data breach. Please choose a different password.{' '}
              <Link href="https://haveibeenpwned.com/About">Learn More</Link>
            </>
          );
        }
      }

      if (Object.keys(errors).length > 0) {
        throw errors;
      }
    },
    values: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  const { firstName, lastName, email, password } = userDetailsForm.values;
  const shouldDisableSubmitButton =
    firstName === '' ||
    lastName === '' ||
    email === '' ||
    password === '' ||
    checkbox.state === false ||
    isPasswordBreached ||
    Object.keys(userDetailsForm.errors).length > 0;

  if (formSubmissionMessage) {
    // The message could be success or failure.
    return (
      <CreateAccountMessage
        email={email}
        message={formSubmissionMessage}
        username={`${firstName} ${lastName}`}
      />
    );
  }

  return (
    <AuthenticationCard>
      <Heading>Create Account</Heading>
      <FormContext.Provider value={userDetailsForm}>
        <BaseForm {...userDetailsForm}>
          <UserDetailFormGrid>
            <div>
              <FormInput
                name="firstName"
                placeholder="First name*"
                required
              />
              <FormMessage name="firstName" />
            </div>
            <div>
              <FormInput
                name="lastName"
                placeholder="Last name*"
                required
              />
              <FormMessage name="lastName" />
            </div>
            <div>
              <FormInput
                name="email"
                type="email"
                placeholder="Email*"
                required
              />
              <FormMessage name="email" />
            </div>
            <div>
              <FormInput
                minLength={8}
                type="password"
                name="password"
                placeholder="Password*"
                required
                ref={passwordInputRef}
              />
              <FormMessage name="password" />
            </div>
            <Flex
              gap="12px"
              justifyContent="flex-end"
            >
              <Checkbox {...checkbox} />
              <TermsAndPrivacyPolicy />
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              gap="28px"
            >
              <GlideButton
                variant="ghost"
                onClick={handleBack}
                className="secondary"
              >
                Back
              </GlideButton>
              <FormSubmitButton
                as={GlideButton}
                variant="primary"
                disabled={shouldDisableSubmitButton}
              >
                Create an account
              </FormSubmitButton>
            </Flex>
          </UserDetailFormGrid>
        </BaseForm>
      </FormContext.Provider>
    </AuthenticationCard>
  );
};

export default CreateAccountWithUserDetails;
