import React from 'react';

import { Flex } from '../../../../components/Flex';
import { DOCUMENT_TYPE } from '../../../../lib/constants';
import { ScrollTarget } from './DocumentDetailPanel.styles';
import * as Styles from './DocumentSharedWithSection.styles';
import DashboardDocumentCard from '../../home/components/DashboardDocumentCard';

function DocumentSharedWithSection({ documentsSharedWithUser }) {
  if (!documentsSharedWithUser.length) {
    return null;
  }

  return (
    <Flex
      direction="column"
      gap="16px"
    >
      <ScrollTarget id={DOCUMENT_TYPE.SHARED_WITH_YOU} />
      <Styles.DocumentTitle level="5">Shared with only you</Styles.DocumentTitle>
      <Styles.Grid>
        {documentsSharedWithUser.map((document) => (
          <DashboardDocumentCard
            key={document.id}
            document={document}
          />
        ))}
      </Styles.Grid>
    </Flex>
  );
}

export default DocumentSharedWithSection;
