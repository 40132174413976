// Package modules
import { useEffect, useRef, useState } from 'react';
import Color from 'color';
import jwtDecode from 'jwt-decode';
import { useContainer } from 'unstated-next';

// Local modules
import {
  CERTIFICATION_STATUS,
  COMPLIANCE_STANDARD_DISPLAY_NAME,
  COMPLIANCE_STANDARDS,
  CONTROL_STATES,
  DOCUMENT_ACCESS_LEVEL,
  DOCUMENT_TYPE,
  OTHER_CONTROLS,
  POLICY_SECURITY_GROUPS,
  QUESTIONNAIRE_EXPORT_STATUS,
  RESOURCE_TYPE,
  ROLE,
  SOC_SUBTYPE_DISPLAY_NAME,
  STANDARDS_WITHOUT_DISPLAY_IDENTIFIER,
  TRUST_SHARE_ACCESS_LEVELS,
} from './constants';
import { ErrorProvider } from './ErrorProvider';

// Constants
const NEW_LINE_CHARACTER = '%0A';
const TRUSTCLOUD_SUPPORT_EMAIL = 'support@trustcloud.ai';
export const NEWLINE_REGEX = new RegExp(/\\n/, 'g'); // Escape the new line character

/**
 * Given a hex string color and opacity as a fraction (e.g. 0.25 for 25% transparent), return a hex string representing the color at the requested opacity.
 * For example, calling `hexColor('#FFFFFF', 0.25)` will return the string `#FFFFFF40`.
 */
export function hexOpacity(hexColor, opacityFraction) {
  const opacityString = Math.floor(255 * opacityFraction)
    .toString(16)
    .padStart(2, '0');
  return `${hexColor}${opacityString}`;
}

export const policyCountBySecurityGroups = (policies) =>
  POLICY_SECURITY_GROUPS.map((type) => ({
    type,
    count: policies.filter((policy) => policy.securityGroup === type).length,
  }));

/**
 * @param {DateTimestamp} timestamp
 * @returns Object
 */
export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return { time: date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).toLowerCase() };
};

/**
 * Given a list of all program policies, this function returns a filtered list based on the securityGroup property
 * @param {[Policy]} policies
 * @param {String} group
 * @returns
 */
export function filterPoliciesBySecurityGroup(policies, group) {
  return policies.filter((policy) => policy.securityGroup === group);
}

/**
 * Given a policyId, this functions returns a count of related controls
 * @param {String} policyId
 * @param {[Controls]} controls
 * @returns
 */
export function computeRelatedControlsCount(relatedControlIds = [], controls) {
  return controls.filter((control) => relatedControlIds.includes(control.id)).length;
}

export function filterForAdoptedControls(controls) {
  return controls.filter((c) => c.state.value === CONTROL_STATES.ADOPTED);
}

/**
 * Given an object, this fuctions returns true if any one of the fields is empty i.e. ''
 * @param {*} fields
 * @returns
 */
export function hasEmptyValue(obj) {
  return Object.values(obj).some((value) => value === '');
}

/**
 * Given an array of program vendors, this functions returns a sorted array of subprocessors
 * with a customer confidential classification
 * @param {[Subprocessors]} rawSubprocessors
 * @returns
 */
export function filterSubprocessorsFromProgramVendors(programVendors) {
  return programVendors
    .filter((pv) => pv?.isSubprocessor === true)
    .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }));
}

/**
 * Given a number, this function returns a pluralized form of the word
 * Very basic, limited implementation -- only appends an `s`.
 * @param {String} word
 * @param {Number} count
 * @returns
 */
export function pluralize(word, count) {
  return count === 1 ? word : `${word}s`;
}

/**
 * Given a string that is the name of a team/program, this function return an introductory copy
 */
export function getProgramIntroductionLabel(programName) {
  return `
    Access to TrustShare allows members to view our Policies, Documents, Certifications,
    and Subprocessors. Compliance is a great matter of importance at ${programName}, and we hope
    to share our journey with you by transparently sharing our practices and accomplishments
    through TrustShare.
  `;
}

// Parses URL hash as key/value pairs.
export const parseFragment = (fragmentString) => {
  const paramString = fragmentString.substring(1); // Remove leading '#'.
  return Object.fromEntries(new URLSearchParams(paramString));
};

/**
 * Given a password, check the Have I Been Pwned database, and return a boolean
 * indiating whether the password has previously been exposed in a data breach.
 */
export async function hasPasswordBeenBreached(password) {
  if (crypto && crypto.subtle && TextEncoder) {
    // Create SHA-1 hash from password.
    const buffer = await crypto.subtle.digest('SHA-1', new TextEncoder('utf-8').encode(password));
    const hash = Array.from(new Uint8Array(buffer))
      .map((x) => x.toString(16).padStart(2, '0'))
      .join('')
      .toUpperCase();

    // Verify hash against API, with timeout of 1s.
    // @see https://haveibeenpwned.com/API/v2#PwnedPasswords
    const result = await Promise.race([
      fetch(`https://api.pwnedpasswords.com/range/${hash.slice(0, 5)}`)
        .then((res) => (res.status === 200 ? res.text() : ''))
        .then((body) => body.includes(hash.slice(5)))
        .catch(() => false), // Non-fatal error, so just return false.
      new Promise((resolve) => setTimeout(resolve, 1000, false)),
    ]);
    return result;
  }
  return false;
}

export async function downloadFileFromAPI(apiCall, defaultFilename) {
  const [blob, filename] = await apiCall();

  // Prompt for export.
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || defaultFilename;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

/**
 * This function is used to modify the location a user is navigated depending on the program access level and the authenticated state
 * For example - For a private TS in a logged out state, the user will be taken to the Request Access page
 * For other uses cases, the originalUrl is returned
 */
export function computeUrlForProgramAccessLevel(programAccessLevel, isLoggedIn, originalUrl) {
  if (programAccessLevel === TRUST_SHARE_ACCESS_LEVELS.PRIVATE && !isLoggedIn) {
    return '/request-access';
  }
  return originalUrl;
}

// This eslint rule is disabled as this function is used to provide default copy descriptions and the rule generally doesn't make sense here
// eslint-disable-next-line complexity
export function getDefaultCertificationDescription(programName, shortName, status, subtype = null) {
  const statusSubstring =
    status === CERTIFICATION_STATUS.COMPLETE ? 'has successfully completed' : 'is actively preparing for';
  if (shortName === COMPLIANCE_STANDARDS.CIS) {
    return `${programName} adheres to the CIS benchmark and demonstrates strong configuration baseline implementation that meets cybersecurity best practices.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.CCPA) {
    return `${programName} complies with the California Consumer Privacy Act (CCPA) and supports our customers’ compliance with the CCPA.`;
  }
  if (
    [COMPLIANCE_STANDARDS.CSA_STAR_L1, COMPLIANCE_STANDARDS.CSA_STAR_L2, COMPLIANCE_STANDARDS.CSA_STAR_L3].includes(
      shortName
    )
  ) {
    return `${programName} complies with the Security, Trust, Assurance, and Risk (STAR) program requirements and demonstrates an adequate security posture and adherence to best security practices.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.CMMC_L1) {
    return `${programName} complies with the Cybersecurity Maturity Model Certification (CMMC) requirements for protecting sensitive unclassified information shared with its contractors and subcontractors.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.GDPR) {
    return `At ${programName}, we are committed to the security and privacy of your data, and we strive to help our customers and users understand and, where applicable, comply with, the General Data Protection Regulation (GDPR).`;
  }
  if (shortName === COMPLIANCE_STANDARDS.FEDRAMP) {
    return `${programName} ${statusSubstring} a FedRamp audit, via an independent assessment by a certified auditor. Our certification demonstrates compliance with a common set of security controls to protect federal information. `;
  }
  if (shortName === COMPLIANCE_STANDARDS.NIST_800_53) {
    return `${programName} complies with the NIST 800-53 requirements and demonstrates adequate security and privacy controls over federal information systems and organizations.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.SOC1) {
    return `${programName} ${statusSubstring} a Service Organization Control 1 (SOC 1) audit, via an independent assessment by a certified auditor.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.SOC2) {
    return `${programName} ${statusSubstring} a Service Organization Control 2 (SOC 2) ${SOC_SUBTYPE_DISPLAY_NAME[subtype]} audit of our control environment via an independent assessment by a certified auditor.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.SOC2_PRIVACY) {
    return `${programName} ${statusSubstring} a Service Organization Control 2 (SOC 2) for the Security and Privacy criteria, via an independent assessment by a certified auditor.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.SOC3) {
    return `${programName} ${statusSubstring} a Service Organization Control 3 (SOC 3) audit, via an independent assessment by a certified auditor.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.SOX) {
    return `${statusSubstring} complies with the Sarbanes-Oxley Act (SOX) requirements and demonstrates strong internal controls over financial reporting and security.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.ISO9001) {
    return `${programName} ${statusSubstring} an ISO 9001 audit, via an independent assessment by a certified auditor. Our certification demonstrates compliance with quality management principles that include strong customer focus, involvement from top management, and continual process improvement.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.ISO27001) {
    return `${programName} ${statusSubstring} an ISO 27001 audit of our Information Security Management System (ISMS) via an independent assessment by a certified auditor.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.ISO27017) {
    return `${programName} ${statusSubstring} an ISO 27017 audit, via an independent assessment by a certified auditor. Our certification demonstrates implementation of strong cloud services information security controls.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.ISO27018) {
    return `${programName} ${statusSubstring} an ISO 27018 audit, via an independent assessment by a certified auditor. Our certification demonstrates an appropriate implementation of controls over personal data in the cloud.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.ISO27701) {
    return `${programName} ${statusSubstring} an ISO 27701 audit, via an independent assessment by a certified auditor. Our certification demonstrates an adequate implementation of a Privacy Management System (PIMS).`;
  }
  if (shortName === COMPLIANCE_STANDARDS.HITRUST) {
    return `${programName} ${statusSubstring} a HITRUST audit, via an independent assessment by a certified auditor. Our certification demonstrates compliance with a common set of security controls mapped to various standards.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.HIPAA) {
    return `${programName} has completed a Health Insurance Portability and Accountability Act (HIPAA) attestation,
      which provides assurances that ${programName} implements adequate measures for saving, accessing, and sharing medical and personal information.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.PCI_DSS) {
    return `${programName} ${statusSubstring} a PCI audit, via an independent assessment by a certified auditor. Our certification demonstrates adequate controls over securing  credit and debit card transactions against data theft and fraud.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.PRIVACY_SHIELD) {
    return `${programName} complies with the Privacy Shield  requirements and demonstrates strong data protection controls over the transfer of data from EU to the US.`;
  }
  if (shortName === COMPLIANCE_STANDARDS.GDPR_PRIVACY) {
    return `At ${programName}, we are committed to the security and privacy of your data, and we strive to help our customers and users understand and, where applicable, comply with, the General Data Protection Regulation (GDPR).`;
  }
  return null;
}

/**
 * Artifacts can be downloaded based on the following conditions -
 *  - access level is private or data room, user has logged in, and filename exists.
 *  - access level is public and filename exists
 */
export function canDownloadCertification(accessLevel, filename, user) {
  if ([DOCUMENT_ACCESS_LEVEL.DATA_ROOM, DOCUMENT_ACCESS_LEVEL.PRIVATE].includes(accessLevel) && user && filename) {
    return true;
  }
  if (accessLevel === DOCUMENT_ACCESS_LEVEL.PUBLIC && filename) {
    return true;
  }
  return false;
}

/**
 * Certification reports that have a link to a document can be viewed based on the following conditions -
 *  - access level is private or data room, user has logged in, and link exists
 *  - access level is public and link exists
 */
export function canViewCertification(accessLevel, link, user) {
  if ([DOCUMENT_ACCESS_LEVEL.DATA_ROOM, DOCUMENT_ACCESS_LEVEL.PRIVATE].includes(accessLevel) && user && link) {
    return true;
  }
  if (accessLevel === DOCUMENT_ACCESS_LEVEL.PUBLIC && link) {
    return true;
  }
  return false;
}

/**
 * Given a hex string for any color, this function returns an array of the RGB values for that color.
 */
export function hexToRgb(hexColor) {
  return hexColor
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
}

/**
 * This function determine the environment of the TrustShare site based on the current URL.
 */
export function isPublishedUrl(publishedUrl) {
  const currentUrl = window.location.origin;
  return publishedUrl === currentUrl;
}

export function deepMerge(updates, initialState) {
  return Object.entries(updates).reduce((baseState, [key, updatedValue]) => {
    if (updatedValue === null) {
      return baseState;
    }
    const { [key]: existingValue, ...updatedState } = baseState;
    if (typeof updatedValue === 'object' && !Array.isArray(updatedValue)) {
      updatedState[key] = deepMerge(updatedValue, existingValue || {});
    } else {
      updatedState[key] = updatedValue;
    }
    return updatedState;
  }, initialState);
}

export const generateBoxShadowColorForPrimaryButton = (buttonBackgroundColor) => {
  if (!buttonBackgroundColor) return null;

  const color = new Color(buttonBackgroundColor);
  const boxShadowColor = color.isDark()
    ? hexOpacity(color.lighten(0.5).hex(), 0.3)
    : hexOpacity(color.darken(0.1).hex(), 0.3);
  return `0px 2px 4px 2px ${boxShadowColor}`;
};

/**
 * This function maps customer provided stylistic properties to existing components in theme
 */
export function transformCustomerConfigToTheme(customerConfig) {
  return {
    components: {
      button: {
        text: { primary: customerConfig?.primaryCTAButton?.textColor },
        background: { primary: customerConfig?.primaryCTAButton?.backgroundColor },
        boxShadow: {
          primary: generateBoxShadowColorForPrimaryButton(customerConfig?.primaryCTAButton?.backgroundColor),
        },
        states: {
          primary: {
            hover: {
              boxShadow: generateBoxShadowColorForPrimaryButton(customerConfig?.primaryCTAButton?.backgroundColor),
            },
            active: { boxShadow: customerConfig?.primaryCTAButton?.backgroundColor ? 'none' : null },
          },
        },
      },
      header: {
        avatar: {
          background: customerConfig?.navigation?.itemTextColor,
          textColor: customerConfig?.header?.backgroundColor,
        },
        connectionIndicator: { backgroundColor: customerConfig?.connectionIndicator?.backgroundColor },
        navigation: {
          backgroundColor: customerConfig?.header?.backgroundColor,
          textColor: customerConfig?.navigation?.itemTextColor,
        },
        trustShareLabel: { textColor: customerConfig?.trustShareLabel?.textColor },
        primaryCTAButton: {
          background: customerConfig?.primaryCTAButton?.backgroundColor,
          textColor: customerConfig?.primaryCTAButton?.textColor,
        },
      },
      footer: {
        background: customerConfig?.header?.backgroundColor,
        boxShadow: customerConfig?.header?.backgroundColor ? 'none' : null,
      },
    },
  };
}

const getBody = ({ username, page, link, error }) => {
  const errorMessage = error || null;
  const errorText = `${NEW_LINE_CHARACTER}${NEW_LINE_CHARACTER}Error: ${errorMessage}`;
  return `Submitted by: ${
    username ?? 'Unauthenticated User'
  }${NEW_LINE_CHARACTER}Page: ${page} (${link})${NEW_LINE_CHARACTER}${NEW_LINE_CHARACTER}Hi TrustCloud Support team,${NEW_LINE_CHARACTER}I need help with the following issue - ${NEW_LINE_CHARACTER}${NEW_LINE_CHARACTER}<please describe your issue>${errorText}`;
};

export const mailtoHandler = (team, username, error) => {
  const title = document.getElementsByTagName('title')[0].text;
  const page = title.split(' | ')[1];
  const link = window.location.href;
  const subject = `${username} is unable to access ${team}’s TrustShare`;
  const body = getBody({
    username,
    page,
    link,
    error,
  });

  /**
   * Using an anchor tag with target=_blank does not work consistently on each browser
   * that we support. For consistency, we force the link to open in a new window
   */
  window.open(`mailto:${TRUSTCLOUD_SUPPORT_EMAIL}?subject=${subject}&body=${body}`, 'supportEmailWindow');
};

export const hasDataRoomAccessToDownloadPolicy = (user, dataRoomIds = [], needsPolicyDataRoomAccess) => {
  const userIsInPolicyDataRoom = user?.dataRoomIds.some((roomId) => dataRoomIds.includes(roomId));
  return needsPolicyDataRoomAccess ? userIsInPolicyDataRoom : true;
};

export const canDownloadPolicy = (accessLevel, authenticatedUser) => {
  if (accessLevel === DOCUMENT_ACCESS_LEVEL.PUBLIC) {
    return true;
  }
  if (
    [DOCUMENT_ACCESS_LEVEL.PRIVATE, DOCUMENT_ACCESS_LEVEL.DATA_ROOM].includes(accessLevel) &&
    authenticatedUser !== null
  ) {
    return true;
  }
  return false;
};

export const canDownloadQuestionnaire = (accessLevel, authenticatedUser) => {
  if (accessLevel === DOCUMENT_ACCESS_LEVEL.PUBLIC) {
    return true;
  }
  if (
    [DOCUMENT_ACCESS_LEVEL.PRIVATE, DOCUMENT_ACCESS_LEVEL.DATA_ROOM].includes(accessLevel) &&
    authenticatedUser !== null
  ) {
    return true;
  }
  return false;
};

/**
 * Accepts an array of vendor locations and returns the locations cast to a string.
 * @param locations
 * @returns {string}
 */
export const getVendorLocationsString = (locations) =>
  locations.length > 0 ? locations.map((location) => location.label).join(', ') : null;

export const modifyPublicAndPrivateDocumentsWithTypeAnnotation = (resources) => {
  const privatePublicDocumentList = resources
    .filter((resource) => [DOCUMENT_ACCESS_LEVEL.PRIVATE, DOCUMENT_ACCESS_LEVEL.PUBLIC].includes(resource.accessLevel))
    .map((resource) => ({
      ...resource,
      documentType: DOCUMENT_TYPE.RESOURCE,
    }));

  return [...privatePublicDocumentList];
};

export const modifyPublicDocumentsWithTypeAnnotation = (resources) => {
  const filteredDocuments = resources
    .filter((resource) => [DOCUMENT_ACCESS_LEVEL.PUBLIC].includes(resource.accessLevel))
    .map((resource) => ({
      ...resource,
      documentType: DOCUMENT_TYPE.RESOURCE,
    }));

  return [...filteredDocuments];
};

export const modifyPrivateDocumentsWithTypeAnnotation = (resources) => {
  const filteredDocuments = resources
    .filter((resource) => [DOCUMENT_ACCESS_LEVEL.PRIVATE].includes(resource.accessLevel))
    .map((resource) => ({
      ...resource,
      documentType: DOCUMENT_TYPE.RESOURCE,
    }));

  return [...filteredDocuments];
};

export const modifyDataRoomDocumentsWithTypeAnnotation = (resources, certifications, policies, questionnaires = []) => {
  const dataRoomResources = resources
    .filter((resource) => resource.accessLevel === DOCUMENT_ACCESS_LEVEL.DATA_ROOM)
    .map((resource) => ({
      ...resource,
      documentType: DOCUMENT_TYPE.RESOURCE,
    }));

  const dataRoomCertifications = certifications
    .filter((certification) => certification.trustShareAccessLevel === DOCUMENT_ACCESS_LEVEL.DATA_ROOM)
    .map((certification) => ({
      ...certification,
      documentType: DOCUMENT_TYPE.CERTIFICATION,
    }));

  const dataRoomPolicies = policies
    .filter((policy) => policy.trustShareAccessLevel === DOCUMENT_ACCESS_LEVEL.DATA_ROOM)
    .map((policy) => ({
      ...policy,
      documentType: DOCUMENT_TYPE.POLICY,
    }));

  const dataRoomQuestionnaires = questionnaires
    .filter(
      (questionnaire) =>
        questionnaire.accessLevel === DOCUMENT_ACCESS_LEVEL.DATA_ROOM &&
        questionnaire.exportStatus === QUESTIONNAIRE_EXPORT_STATUS.COMPLETE
    )
    .map((questionnaire) => ({
      ...questionnaire,
      documentType: DOCUMENT_TYPE.QUESTIONNAIRE,
    }));

  // Return the documents in the order of the sections of the page i.e certification, policies, resources
  return [...dataRoomCertifications, ...dataRoomPolicies, ...dataRoomResources, ...dataRoomQuestionnaires];
};

export const canDownloadResource = (type, accessLevel, authenticatedUser) => {
  if (
    type === RESOURCE_TYPE.FILE &&
    [DOCUMENT_ACCESS_LEVEL.PRIVATE, DOCUMENT_ACCESS_LEVEL.RESTRICTED, DOCUMENT_ACCESS_LEVEL.DATA_ROOM].includes(
      accessLevel
    ) &&
    authenticatedUser
  ) {
    return true;
  }
  if (type === RESOURCE_TYPE.FILE && accessLevel === DOCUMENT_ACCESS_LEVEL.PUBLIC) {
    return true;
  }
  return false;
};

export const canViewResource = (type, accessLevel, authenticatedUser) => {
  if (
    type === RESOURCE_TYPE.LINK &&
    [DOCUMENT_ACCESS_LEVEL.PRIVATE, DOCUMENT_ACCESS_LEVEL.RESTRICTED, DOCUMENT_ACCESS_LEVEL.DATA_ROOM].includes(
      accessLevel
    ) &&
    authenticatedUser
  ) {
    return true;
  }
  if (type === RESOURCE_TYPE.LINK && accessLevel === DOCUMENT_ACCESS_LEVEL.PUBLIC) {
    return true;
  }
  return false;
};

export const getCertificationReportDisplayName = (shortName, subtype = null) => {
  if (subtype) {
    return `${COMPLIANCE_STANDARD_DISPLAY_NAME[shortName][subtype]}`;
  }
  return COMPLIANCE_STANDARD_DISPLAY_NAME[shortName];
};

/**
 * Returns a function that sorts an array of objects by the provided property name
 * @param {string} prop the name of the property name to sort by
 * @returns a sorted array
 */
export const sortByProp = (prop) => (a, b) =>
  a[prop]?.toString()?.localeCompare(b[prop]?.toString(), 'en', { numeric: true });

/**
 * This functions returns the appropriate url based on if a user is logged-in
 * and the access level of the TS set by the customer
 */
export const getSubpageUrl = (accessLevel, authenticatedUser, subpageUrl) => {
  if (accessLevel === TRUST_SHARE_ACCESS_LEVELS.PRIVATE && authenticatedUser === null) {
    return '/request-access';
  }
  return subpageUrl;
};

export function getTrustLeaderDisplayName(trustLeader) {
  return trustLeader.firstName && trustLeader.lastName
    ? `${trustLeader.firstName} ${trustLeader.lastName}`
    : trustLeader?.user?.name;
}

// Transform the data from the API to the format that the component expects
export function transformControlsByCategory(adoptedControls) {
  const transformedControls = { [OTHER_CONTROLS]: [] };
  adoptedControls.forEach((control) => {
    if (!control.categorization.category) {
      transformedControls[OTHER_CONTROLS] = [...transformedControls[OTHER_CONTROLS], control];
    } else if (transformedControls[control.categorization.category]) {
      transformedControls[control.categorization.category] = [
        ...transformedControls[control.categorization.category],
        control,
      ];
    } else {
      transformedControls[control.categorization.category] = [control];
    }
  });
  return transformedControls;
}

/**
 * Hook returning true on first render, false otherwise.
 * @see https://www.benmvp.com/blog/8-helpful-custom-react-hooks/
 */
export function useInitialMount() {
  const isFirst = useRef(true);
  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }
  return false;
}

export function parseJwt(token) {
  try {
    return jwtDecode(token);
  } catch (e) {
    return null;
  }
}

export function useQueryError() {
  const { setError } = useContainer(ErrorProvider);
  return { setError: (error) => (error?.silent ? null : setError(error)) };
}

const sortByReferenceId = sortByProp('referenceId');
const sortByTitle = sortByProp('title');
const sortByDisplayIdentifier = sortByProp('displayIdentifier');

// Sorting framework sections function.
export const sortSections = (a, b, framework) => {
  if (
    STANDARDS_WITHOUT_DISPLAY_IDENTIFIER.includes(framework.shortName) &&
    framework.shortName === COMPLIANCE_STANDARDS.CMMC_L1
  ) {
    return sortByReferenceId(a, b);
  }
  if (
    STANDARDS_WITHOUT_DISPLAY_IDENTIFIER.includes(framework.shortName) &&
    framework.shortName !== COMPLIANCE_STANDARDS.CMMC_L1
  ) {
    return sortByTitle(a, b);
  }
  if (a.displayIdentifier && b.displayIdentifier) {
    return sortByDisplayIdentifier(a, b);
  }
  if (framework.shortName === COMPLIANCE_STANDARDS.CMMC_L1) {
    return sortByReferenceId(a, b);
  }
  return sortByTitle(a, b);
};

export const stripPolicyTitleSuffix = (policies) =>
  policies.map((policy) => ({
    ...policy,
    title: policy.title.replace(/ Policy$/, ''),
  }));

export function isElementInViewport(elementId) {
  const element = document.getElementById(elementId);

  if (!element) {
    // Element with the given ID not found
    return false;
  }

  const rect = element.getBoundingClientRect();
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  const viewportBoxHeight = 200;
  const viewportBoxTop = (viewportHeight - viewportBoxHeight) / 2;
  const viewportBoxBottom = viewportBoxTop + viewportBoxHeight;

  return rect.top >= viewportBoxTop && rect.bottom <= viewportBoxBottom;
}

export function useDebounce(value, delay = 200) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(setDebouncedValue, delay, value);

    return () => clearTimeout(timer);
  }, [value, delay]);

  return debouncedValue;
}

export function getPoliciesForGroup(policies, group) {
  return policies.filter((policy) => policy.securityGroup === group);
}

export function shouldHideItem(isRequestAccessHidden, conditions) {
  if (isRequestAccessHidden) {
    return conditions.every((condition) => !condition);
  }
  return false;
}

export function canSeeCustomizeModal(user, data, roles) {
  const { id: auditorRoleId } = roles.find((role) => role.name === ROLE.AUDITOR);
  const { id: complianceTeamMemberId } = roles.find((role) => role.name === ROLE.COMPLIANCE_TEAM_MEMBER);
  const { id: trustShareUser } = roles.find((role) => role.name === ROLE.TRUST_SHARE_USER);

  const hasNotSeenGuidance = !data?.global.hasSeenGuidance?.trustShareCustomize;
  const hasRestrictedRoles = [auditorRoleId, complianceTeamMemberId, trustShareUser].some((role) =>
    user.roles.includes(role)
  );

  return hasNotSeenGuidance && !hasRestrictedRoles;
}

export function darkenHexColor(hexInput, percent) {
  const hex = hexInput.replace('#', '');

  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  r = Math.max(0, Math.min(255, Math.floor(r * (1 - percent / 100))));
  g = Math.max(0, Math.min(255, Math.floor(g * (1 - percent / 100))));
  b = Math.max(0, Math.min(255, Math.floor(b * (1 - percent / 100))));

  return `#${(r * 0x10000 + g * 0x100 + b).toString(16).padStart(6, '0')}`;
}

export function lightenHexColor(hexInput, percent) {
  const hex = hexInput.replace('#', '');

  const r = Math.min(
    255,
    Math.floor(parseInt(hex.substring(0, 2), 16) + (255 - parseInt(hex.substring(0, 2), 16)) * (percent / 100))
  );
  const g = Math.min(
    255,
    Math.floor(parseInt(hex.substring(2, 4), 16) + (255 - parseInt(hex.substring(2, 4), 16)) * (percent / 100))
  );
  const b = Math.min(
    255,
    Math.floor(parseInt(hex.substring(4, 6), 16) + (255 - parseInt(hex.substring(4, 6), 16)) * (percent / 100))
  );

  return `#${(r * 0x10000 + g * 0x100 + b).toString(16).padStart(6, '0')}`;
}

const FILE_TYPE = {
  LINK: 'link',
  DOCUMENT: 'document',
};

const getTitle = (name, type) => {
  if (type === FILE_TYPE.DOCUMENT) {
    // Remove file extension and capitalize first letter
    return name.replace(/\.[^/.]+$/, '').replace(/(^\w)/, (c) => c.toUpperCase());
  }
  if (type === FILE_TYPE.LINK) {
    // Replace http:// or https:// with empty string and capitalize first letter
    return name.replace(/(^\w+:|^)\/\//, '').replace(/(^\w)/, (c) => c.toUpperCase());
  }
  return name;
};

export const getTeamCertificationsWithChildren = (teamCertifications = []) => {
  const teamCertificationsWithChildren = teamCertifications.map((teamCertification) => {
    const result = { ...teamCertification };

    // Add title to each rawChildren object based on the type of file
    const children = teamCertification?.children
      .filter((child) => (child.filename || child.certificationUrl) && child.trustShareAccessLevel != null)
      .map((child = {}) => {
        const { filename, certificationUrl } = child;
        if (filename) {
          return {
            ...child,
            title: child.title ?? getTitle(filename, FILE_TYPE.DOCUMENT),
          };
        }
        if (certificationUrl) {
          return {
            ...child,
            // Generic link text to hide the information
            title: child.title ?? 'Link',
          };
        }
        return {};
      });

    result.children = children;

    return result;
  });
  return teamCertificationsWithChildren;
};
