import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { SENTRY_DSN } from '../constants';

function initializeSentry() {
  try {
    Sentry.init({
      attachStacktrace: true,
      debug: process.env.NODE_ENV !== 'production',
      // Disable the TS render preview environments from firing Sentry errors
      denyUrls: ['https://trustshare-preview-pr'],
      dsn: SENTRY_DSN,
      enabled: process.env.NODE_ENV === 'production',
      environment: process.env.NODE_ENV,
      replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1,
      replaysOnErrorSampleRate: 1,
      integrations: [
        new BrowserTracing({ tracingOrigins: ['*'] }),
        new Sentry.Replay({
          /**
           * This is intentionally set to false so we can accurately see
           * where in the user journey the application threw an error
           */
          maskAllText: false,
          // https://github.com/getsentry/sentry-javascript/issues/6739
          useCompression: false,
        }),
      ],
      release: process.env.REACT_APP_RELEASE_BRANCH_NAME,
      tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 1,
      ignoreErrors: ['Non-Error promise rejection captured'],
    });
  } catch (e) {
    // Sentry has not loaded successfully.
  }
}

export default initializeSentry;
