import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-navi';
import { Heading, Typography } from '@kintent/glide';

import { Flex } from '../../../../components/Flex';
import { TRUSTCLOUD_WEBSITE_URL } from '../../../../lib/constants';
import CountBox from '../../../../components/CountBox';

const ControlMappingSectionDescription = styled(Typography)`
  line-height: 1.7;

  & > a {
    color: ${({ theme }) => theme.palette.volare};

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
    }

    transition: color 250ms ease-in;
  }
`;

function SubprocessorPageSection({ subProcessorName, numberOfControls }) {
  return (
    <>
      <Flex
        gap="8px"
        alignItems="center"
      >
        <CountBox count={numberOfControls} />
        <Heading level="5">Controls Monitored for {subProcessorName}</Heading>
      </Flex>
      <ControlMappingSectionDescription
        as="p"
        level="9"
      >
        {subProcessorName} in governed by our adherence to the following controls in our security, privacy, and trust
        program, which are continuously monitored and verified by{' '}
        <Link
          href={TRUSTCLOUD_WEBSITE_URL}
          target="_blank"
        >
          TrustCloud
        </Link>
        :
      </ControlMappingSectionDescription>
    </>
  );
}

export default SubprocessorPageSection;
