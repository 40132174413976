import React from 'react';
import { flat, Heading, Typography } from '@kintent/glide';
import { useTheme } from '@emotion/react';
import { Link } from 'react-navi';

import { FEATURE_FLAG } from 'lib/featureFlags';
import * as Styles from './DashboardCertificationItemCard.styles';
import { Flex } from '../../../../components/Flex';
import { useAuthService, useFeatureFlag } from '../../../../lib/state';
import {
  getCertificationCardTitle,
  getCertificationLogo,
  getCertificationLogoSrcSet,
  TRUST_SHARE_ACCESS_LEVELS,
} from '../../../../lib/constants';
import { canDownloadCertification, canViewCertification } from '../../../../lib/utils';
import { GlideButton } from '../../../../components/GlideButton';

// Constants

function getCertificationDetailPageUrl(featureFlagEnabled = false, shortName, subtype = null) {
  if (featureFlagEnabled) {
    return subtype ? `/certifications/${shortName}${subtype}` : `/certifications/${shortName}`;
  }
  return '/certifications';
}

function DashboardCertificationSectionItemCard({
  accessLevel,
  certificationUrl,
  description,
  filename,
  shortName,
  subtype,
  documentCount = 0,
}) {
  const theme = useTheme();

  const { authenticatedUser, currentTeam } = useAuthService();
  const isNewCertificationDetailEnabled = useFeatureFlag(FEATURE_FLAG.CERTIFICATION_DETAIL);
  const isRequestAccessHidden = useFeatureFlag(FEATURE_FLAG.HIDE_REQUEST_ACCESS);

  const canDownloadFile = canDownloadCertification(accessLevel, filename, authenticatedUser);
  const canViewFile = canViewCertification(accessLevel, certificationUrl, authenticatedUser);

  const certificationDetailPageUrl = getCertificationDetailPageUrl(isNewCertificationDetailEnabled, shortName, subtype);

  const shouldHideOverlay =
    isRequestAccessHidden &&
    !canDownloadFile &&
    !canViewFile &&
    !authenticatedUser &&
    currentTeam.trustShareAccessLevel !== TRUST_SHARE_ACCESS_LEVELS.TRANSPARENT;

  return (
    <>
      <Styles.Card>
        <Flex justifyContent="space-between">
          <Styles.LogoWrapper>
            <img
              alt="Certification Logo"
              src={getCertificationLogo(shortName, subtype)}
              srcSet={getCertificationLogoSrcSet(shortName)}
            />
          </Styles.LogoWrapper>
        </Flex>
        <div>
          <Heading
            level="6"
            color={flat(theme.color.system.gray, '100%')}
          >
            {getCertificationCardTitle(shortName, subtype)}
            {documentCount !== 0 && (
              <span style={{ color: flat(theme.color.system.gray, '60%') }}> · {documentCount}</span>
            )}
          </Heading>
          <Typography level="body">{description}</Typography>
        </div>
        {!shouldHideOverlay && (
          <Styles.Overlay className="overlay">
            {currentTeam.trustShareAccessLevel === TRUST_SHARE_ACCESS_LEVELS.TRANSPARENT && (
              <GlideButton
                as={Link}
                href={certificationDetailPageUrl}
                variant="secondary"
                className="secondary"
              >
                Learn More
              </GlideButton>
            )}
          </Styles.Overlay>
        )}
      </Styles.Card>
    </>
  );
}

export default DashboardCertificationSectionItemCard;
