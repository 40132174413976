import React from 'react';

import DashboardDocumentCard from './DashboardDocumentCard';
import DashboardDocumentCardViewMore from './DashboardDocumentCardViewMore';
import * as Styles from './DashboardDocuments.styles';

const MAX_DOCUMENTS = 8;

function DashboardDocumentsGrid({ documents }) {
  return (
    <Styles.Grid>
      {documents.map((document, index) => {
        if (index === MAX_DOCUMENTS)
          return (
            <DashboardDocumentCardViewMore
              count={documents.length - MAX_DOCUMENTS}
              key={document.id}
            />
          );
        if (index > MAX_DOCUMENTS) return null;
        return (
          <DashboardDocumentCard
            key={document.id}
            document={document}
          />
        );
      })}
    </Styles.Grid>
  );
}

export default DashboardDocumentsGrid;
