import React from 'react';
import { Heading } from '@kintent/glide';
import { Link } from 'react-navi';

import { useContentVisibility, usePolicyList } from '../../../../lib/state';
import { DOCUMENT_ACCESS_LEVEL, DOCUMENT_TYPE, POLICY_SECURITY_GROUPS } from '../../../../lib/constants';
import { getPoliciesForGroup, sortByProp } from '../../../../lib/utils';
import { ScrollTarget } from './DocumentDetailPanel.styles';
import * as Styles from './DocumentPolicySection.styles';
import { Flex } from '../../../../components/Flex';
import DashboardPolicySectionItem from '../../home/components/DashboardPolicySectionItem';

const sortByTitle = sortByProp('title');
const SECURITY_GROUPS = Object.values(POLICY_SECURITY_GROUPS);

function DocumentPolicySection() {
  const { shouldHidePolicyDetail: shouldHideLinkForPolicyDetails, shouldHidePolicyOverview } = useContentVisibility();
  const [policies] = usePolicyList();
  const visiblePolicies = policies
    .filter((policy) =>
      [DOCUMENT_ACCESS_LEVEL.PUBLIC, DOCUMENT_ACCESS_LEVEL.PRIVATE].includes(policy.trustShareAccessLevel)
    )
    .sort(sortByTitle);

  if (!visiblePolicies.length || (shouldHideLinkForPolicyDetails && shouldHidePolicyOverview)) {
    return null;
  }

  return (
    <Flex
      direction="column"
      gap="16px"
    >
      <ScrollTarget id={DOCUMENT_TYPE.POLICY} />
      <Styles.DocumentTitle level="5">Policies</Styles.DocumentTitle>
      <Styles.Grid>
        {SECURITY_GROUPS.map((group) => {
          const policiesPerGroup = getPoliciesForGroup(visiblePolicies, group);

          if (policiesPerGroup.length === 0) return null;
          return (
            <Styles.Card
              key={group}
              gap="16px"
              direction="column"
            >
              <Flex
                alignItems="center"
                gap="4px"
              >
                <Heading level="5">{group}</Heading>
                <Styles.PolicyLengthLabel level="6">·</Styles.PolicyLengthLabel>
                <Styles.PolicyLengthLabel level="6">{policiesPerGroup.length}</Styles.PolicyLengthLabel>
              </Flex>
              <div>
                {policiesPerGroup.map((policy) => (
                  <DashboardPolicySectionItem
                    accessLevel={policy.trustShareAccessLevel}
                    key={policy.id}
                    policyId={policy.id}
                    shortName={policy.shortName}
                    shouldHideLinkForPolicyDetails={shouldHideLinkForPolicyDetails}
                    title={policy.title}
                    dataRoomIds={policy.dataRoomIds}
                  />
                ))}
              </div>
              {!shouldHideLinkForPolicyDetails && (
                <Styles.ViewAllLink
                  as={Link}
                  href="/policies"
                  level="7"
                >
                  View All
                </Styles.ViewAllLink>
              )}
            </Styles.Card>
          );
        })}
      </Styles.Grid>
    </Flex>
  );
}

export default DocumentPolicySection;
