// Package modules
import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-navi';
import { useTheme } from '@emotion/react';
import { unstable_Form as BaseForm, unstable_useFormState as useFormState } from 'reakit';
import { UilArrowLeft as ArrowLeftIcon } from '@iconscout/react-unicons';

// Local modules
import { Typography } from '@kintent/glide';
import { FormContext, FormInput, FormMessage, SpaceAround } from '../../../design-system';
import { Flex } from '../../../components/Flex';
import { FormContainer, GoToSignInButton } from '../components/AuthFlowComponents';
import { CardTitle } from '../../../components/PageElements';
import ApiError, { VALIDATION_ERRORS } from '../../../lib/errors/ApiError';
import { api } from '../../../lib/api';
import { useDeviceResolution } from '../../../lib/hooks';
import { BREAKPOINTS } from '../../../lib/constants';
import { GlideButton } from '../../../components/GlideButton';

// Styled components
const FormActionWrapper = styled(SpaceAround)`
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    & {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }
`;

const ForgotPasswordEmailForm = ({ handleResetEmailSent }) => {
  const theme = useTheme();
  const emailFieldRef = useRef();

  const forgotPasswordForm = useFormState({
    onSubmit: async ({ email }) => {
      try {
        await api.auth.forgotPassword(email);
        handleResetEmailSent(true);
      } catch (e) {
        const errors = { email: e.sourceError?.error };

        if (e instanceof ApiError && [VALIDATION_ERRORS].includes(e.sourceError.error)) {
          errors.email = 'Please enter a valid email address';
        }

        throw errors;
      }
    },
    onValidate: ({ email }) => {
      const errors = {};

      if (emailFieldRef.current.checkValidity() === false || email.trim().length === 0) {
        errors.email = 'Please enter a valid email address';
      }

      if (Object.keys(errors).length > 0) {
        throw errors;
      }
    },
    resetOnSubmitSucceed: true,
    validateOnChange: true,
    validateOnBlur: true,
    values: { email: '' },
  });

  /**
   * On the first form render, form.valid is true (not sure why).
   * This is a workaround to disable the submit button with additional logic that ensures
   * it is disabled when the value is empty or when the form is not valid
   */
  const disableResetButton = forgotPasswordForm.values.email.trim().length === 0 || !forgotPasswordForm.valid;

  return (
    <FormContext.Provider value={forgotPasswordForm}>
      <BaseForm {...forgotPasswordForm}>
        <FormInput
          name="email"
          type="email"
          required
          placeholder="Email address"
          ref={emailFieldRef}
        />
        <FormMessage name="email" />
        <FormActionWrapper
          as={Flex}
          justifyContent="flex-end"
          alignItems="center"
          top="40px"
          bottom="40px"
          gap="24px"
        >
          <GlideButton
            variant="ghost"
            icon={
              <ArrowLeftIcon
                color={theme.components.header.primaryCTAButton.background}
                size={16}
              />
            }
            as={Link}
            href="/login"
            className="ghost"
          >
            Back
          </GlideButton>
          <GlideButton
            onClick={forgotPasswordForm.submit}
            disabled={disableResetButton}
          >
            Reset Password
          </GlideButton>
        </FormActionWrapper>
      </BaseForm>
    </FormContext.Provider>
  );
};

export const ForgotPasswordForm = () => {
  const [resetEmailSent, setResetEmail] = useState(false);
  const { isTablet } = useDeviceResolution();

  return (
    <>
      <CardTitle>{resetEmailSent ? 'Reset email sent' : 'Forgot Password'}</CardTitle>
      <FormContainer
        top={isTablet ? '60px' : '20px'}
        bottom="40px"
      >
        <Typography level="8">
          {resetEmailSent
            ? 'Please check your email for the link to reset your password.'
            : 'Please enter your email below. If there is an account associated with this email, you will receive a link to reset your password.'}
        </Typography>
        {resetEmailSent ? (
          <SpaceAround
            as={Flex}
            top="60px"
            justifyContent="center"
          >
            <GoToSignInButton
              as={Link}
              href="/login"
            >
              Done
            </GoToSignInButton>
          </SpaceAround>
        ) : (
          <ForgotPasswordEmailForm handleResetEmailSent={setResetEmail} />
        )}
      </FormContainer>
    </>
  );
};
