import React, { useState } from 'react';
import { Heading, TabContent, TabRoot, TabTrigger } from '@kintent/glide';

import DashboardDocumentsGrid from './DashboardDocumentsGrid';
import * as Styles from './DashboardDocuments.styles';
import { Flex } from '../../../../components/Flex';
import {
  useAuthService,
  usePolicyList,
  useSharedQuestionnaireList,
  useTeamCertificationList,
  useTeamDocumentList,
} from '../../../../lib/state';
import {
  modifyDataRoomDocumentsWithTypeAnnotation,
  modifyPrivateDocumentsWithTypeAnnotation,
  modifyPublicDocumentsWithTypeAnnotation,
} from '../../../../lib/utils';

const TABS = {
  ALL: 'ALL',
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
  SHARED_WITH_YOU: 'SHARED WITH ONLY YOU',
};

const TAB_VALUES = {
  ALL: 'all',
  PUBLIC: 'public',
  PRIVATE: 'private',
  SHARED_WITH_YOU: 'shared',
};

function DashboardDocuments() {
  const { authenticatedUser, currentTeam } = useAuthService();
  const [documentList] = useTeamDocumentList(currentTeam.id);
  const [certificationList] = useTeamCertificationList(currentTeam.id);
  const [policies] = usePolicyList();
  const questionnaires = useSharedQuestionnaireList(currentTeam.id);

  const documentsSharedWithUser = modifyDataRoomDocumentsWithTypeAnnotation(
    documentList,
    certificationList,
    policies,
    questionnaires
  );
  const publicDocuments = modifyPublicDocumentsWithTypeAnnotation(documentList);
  const privateDocuments = modifyPrivateDocumentsWithTypeAnnotation(documentList);

  const shouldDisplayDocumentsForYouSection = Boolean(authenticatedUser && documentsSharedWithUser.length > 0);

  const [activeTab, setActiveTab] = useState(
    shouldDisplayDocumentsForYouSection ? TAB_VALUES.SHARED_WITH_YOU : TAB_VALUES.ALL
  );

  const allDocuments = [...documentsSharedWithUser, ...publicDocuments, ...privateDocuments];

  const documentCountBasedOnTab = {
    [TAB_VALUES.ALL]: allDocuments.length,
    [TAB_VALUES.PUBLIC]: publicDocuments.length,
    [TAB_VALUES.PRIVATE]: privateDocuments.length,
    [TAB_VALUES.SHARED_WITH_YOU]: documentsSharedWithUser.length,
  };

  if (!allDocuments.length) {
    return null;
  }

  return (
    <Styles.DashboardLayoutContainer>
      <TabRoot
        defaultValue={activeTab}
        onValueChange={setActiveTab}
      >
        <Flex
          gap="16px"
          alignItems="center"
        >
          <Styles.CountBadge variant="primary">
            {documentCountBasedOnTab[activeTab ?? TAB_VALUES.SHARED_WITH_YOU]}
          </Styles.CountBadge>
          <Flex
            gap="24px"
            alignItems="center"
          >
            <Heading level="3">Documents</Heading>
            <Styles.DocumentTabList>
              {shouldDisplayDocumentsForYouSection && (
                <TabTrigger value={TAB_VALUES.SHARED_WITH_YOU}>{TABS.SHARED_WITH_YOU}</TabTrigger>
              )}
              {allDocuments.length > 0 && <TabTrigger value={TAB_VALUES.ALL}>{TABS.ALL}</TabTrigger>}
              {publicDocuments.length > 0 && <TabTrigger value={TAB_VALUES.PUBLIC}>{TABS.PUBLIC}</TabTrigger>}
              {privateDocuments.length > 0 && <TabTrigger value={TAB_VALUES.PRIVATE}>{TABS.PRIVATE}</TabTrigger>}
            </Styles.DocumentTabList>
          </Flex>
        </Flex>
        {shouldDisplayDocumentsForYouSection && (
          <TabContent value={TAB_VALUES.SHARED_WITH_YOU}>
            <DashboardDocumentsGrid documents={documentsSharedWithUser} />
          </TabContent>
        )}
        {allDocuments.length > 0 && (
          <TabContent value={TAB_VALUES.ALL}>
            <DashboardDocumentsGrid documents={allDocuments} />
          </TabContent>
        )}
        {publicDocuments.length > 0 && (
          <TabContent value={TAB_VALUES.PUBLIC}>
            <DashboardDocumentsGrid documents={publicDocuments} />
          </TabContent>
        )}
        {privateDocuments.length > 0 && (
          <TabContent value={TAB_VALUES.PRIVATE}>
            <DashboardDocumentsGrid documents={privateDocuments} />
          </TabContent>
        )}
      </TabRoot>
    </Styles.DashboardLayoutContainer>
  );
}

export default DashboardDocuments;
