import { createClient } from 'configcat-js';

const CONFIGCAT_DEV_SDK_KEY = 'U1LZCJjCt0SJTlnADO4xEA/zAzrIhIEmEGtUP_Khl4sVw';
export const CONFIGCAT_KEY = process.env.REACT_APP_CONFIGCAT_KEY || CONFIGCAT_DEV_SDK_KEY;

export const configCatClient = createClient(CONFIGCAT_KEY);

export const FEATURE_FLAG = {
  CERTIFICATION_DETAIL: 'certificationDetail',
  SELF_SERVE_RESTRICTED_EMAIL_DOMAINS: 'selfServeRestrictedEmailDomains',
  HIDE_REQUEST_ACCESS: 'hideRequestAccess',
  SPECIFIC_DESCRIPTIONS: 'tsSpecificDescriptions',
  POLICY_DATA_ROOM_ACCESS: 'policyDownloadsRestricted',
};
