import styled from '@emotion/styled';
import { Badge, Button, flat, Typography } from '@kintent/glide';

import HomePageContainer from './HomePageContainer';
import { Flex } from '../../../../components/Flex';

export const TrustLeadersSectionContainer = styled(HomePageContainer)`
  margin-top: 72px;
`;

export const FlexContainer = styled(Flex)`
  max-width: 1032px; /* this is to make sure we have only 4 columns and not more */
  height: 100%;
`;

export const ViewAllButton = styled(Button)`
  width: 240px;
  height: 163px;
  min-height: 100%;
  color: ${({ theme }) => flat(theme.color.brand.primary)};
  border-color: ${({ theme }) => flat(theme.color.brand.primary)};
  border-radius: 8px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const DescriptionLabel = styled(Typography)`
  color: ${({ theme }) => flat(theme.color.system.gray, '80%')};
`;

export const CountBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
`;
