import React, { useEffect, useRef, useState } from 'react';

import * as Styles from './DashboardTrustLeaderItem.styles';
import { Flex } from '../../../../components/Flex';
import { useTeamLeaderAvatar } from '../../../../lib/state';
import { getTrustLeaderDisplayName } from '../../../../lib/utils';
import DefaultProfileIcon from '../../../../assets/default-profile.svg';

export function DashboardTrustLeaderItem({ trustLeader = {} }) {
  const avatarRef = useRef();
  const [leaderAvatar, setLeaderAvatar] = useState(null);
  const [trustLeaderAvatar] = useTeamLeaderAvatar(trustLeader.teamId, trustLeader.id, trustLeader.avatarFilename);

  const { leaderRole, user = null } = trustLeader;
  const displayName = getTrustLeaderDisplayName(trustLeader);

  useEffect(() => {
    if (trustLeaderAvatar) {
      setLeaderAvatar(trustLeaderAvatar);
    }
  }, [trustLeaderAvatar]);

  return (
    <Styles.FlexItem
      direction="column"
      gap="16px"
      alignItems="center"
    >
      <Styles.LeaderAvatar
        size={60}
        ref={avatarRef}
        src={leaderAvatar || user?.pictureUrl || DefaultProfileIcon}
        name={displayName}
        onError={() => {
          avatarRef.current.src = DefaultProfileIcon;
        }}
      />
      <Flex
        direction="column"
        gap="3px"
      >
        <Styles.LabelTypography>{displayName}</Styles.LabelTypography>
        <Styles.LabelTypography level="8">{leaderRole}</Styles.LabelTypography>
      </Flex>
    </Styles.FlexItem>
  );
}
