import React from 'react';
import styled from '@emotion/styled';
import { Badge, Heading } from '@kintent/glide';

import DashboardCertificationSectionItemCard from './DashboardCertificationItemCard';
import HomePageContainer from './HomePageContainer';
import { Flex } from '../../../../components/Flex';
import { useAuthService, useTeamCertificationList } from '../../../../lib/state';
import { getDefaultCertificationDescription, getTeamCertificationsWithChildren } from '../../../../lib/utils';
import { DOCUMENT_ACCESS_LEVEL } from '../../../../lib/constants';

// Styled components
const CertificiationLayoutContainer = styled(HomePageContainer)`
  margin-top: 72px;
`;

const CertificationListGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
`;

const StyledBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
`;

function DashboardCertificationSection() {
  const { currentTeam } = useAuthService();
  const [rawCertifications] = useTeamCertificationList(currentTeam.id);
  const certifications = getTeamCertificationsWithChildren(rawCertifications);
  const visibleCertifications = certifications.filter((item) => {
    return item.children.filter((child) => {
      return [DOCUMENT_ACCESS_LEVEL.PUBLIC, DOCUMENT_ACCESS_LEVEL.PRIVATE].includes(child.trustShareAccessLevel);
    });
  });

  // Return early when there are no certifications for display
  if (visibleCertifications.length === 0) return null;

  return (
    <CertificiationLayoutContainer as="section">
      <Flex
        direction="column"
        gap="16px"
      >
        <Flex
          gap="16px"
          alignItems="center"
        >
          <StyledBadge variant="primary">{visibleCertifications.length}</StyledBadge>
          <Heading level="3">Certifications</Heading>
        </Flex>
        <CertificationListGrid>
          {visibleCertifications?.map((item) => (
            <DashboardCertificationSectionItemCard
              key={item.id}
              accessLevel={item.trustShareAccessLevel}
              certificationId={item.id}
              certificationUrl={item.certificationUrl}
              documentCount={item.children.length}
              description={
                item.description ??
                getDefaultCertificationDescription(
                  currentTeam?.name,
                  item.certification.shortName,
                  item.status,
                  item.subtype
                )
              }
              filename={item.filename}
              shortName={item.certification.shortName}
              status={item.status}
              subtype={item.subtype}
            />
          ))}
        </CertificationListGrid>
      </Flex>
    </CertificiationLayoutContainer>
  );
}

export default DashboardCertificationSection;
